import React from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';

const EmptyState = ({ title, subtitle, btnLabel, btnColor, onClick }) => (
  <Wrapper>
    <Title>{title}</Title>
    <Subtitle>{parse(subtitle)}</Subtitle>
    <Btn color={btnColor} onClick={onClick}>
      {btnLabel}
    </Btn>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  max-width: 480px;
  text-align: center;

  padding: 30px 24px 60px;

  @media only screen and (max-width: 768px) {
    max-width: 320px;
  }
`;

const Title = styled.div`
  color: ${(props) => props.theme.textColors.primary};
  font-size: 28px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 24px;
  text-align: center;
`;

const Subtitle = styled.p`
  font-size: 20px;
  line-height: 1.5;
  color: ${(props) => props.theme.textColors.secondary};
  text-align: center;
`;

const Btn = styled.button`
  padding: 0 40px;
  background-color: ${(props) => props.color || props.theme.colors.primary};
  color: white;
  font-size: 18px;
  font-weight: 500;
  border-radius: 16px;
  height: 52px;
  margin-top: 32px;
`;

export default EmptyState;
