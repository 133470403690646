// @flow
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { MdOutlineBackspace, MdExpandMore } from 'react-icons/md';
import {
  formatTimestamp,
  convertTimeToFields,
  timestampToSeconds,
} from '../../hooks/useTextHelper';

// type Props = {
//   onSave: Function,
//   onClose: Function,
//   currentTime: Number,
//   max: Number,
//   min: Number,
// };

const SECOND_PATTERN = [
  { type: 'digit', field: 's1' },
  { type: 'digit', field: 's2' },
];
const MINUTE_PATTERN = [
  { type: 'digit', field: 'm1' },
  { type: 'digit', field: 'm2' },
  { type: 'text', value: ':' },
  ...SECOND_PATTERN,
];
const HOUR_PATTERN = [
  { type: 'digit', field: 'h1' },
  { type: 'digit', field: 'h2' },
  { type: 'text', value: ':' },
  ...MINUTE_PATTERN,
];

const DEFAULT_ACTIVE_DIGITS = 9;
const TIME_ORDER = ['h1', 'h2', 'm1', 'm2', 's1', 's2'];

const DigitInput = ({ active, onFocus, field, value, disabled }) => (
  <DigitWrapper>
    <DigitBtn
      onClick={() => onFocus(field)}
      active={active}
      disabled={disabled}>
      {value}
    </DigitBtn>
    <DigitLabel>{field.charAt(0)}</DigitLabel>
  </DigitWrapper>
);

const KeypadButton = ({ onPress, num, deleteMode, disabled }) => (
  <KeypadBtn onClick={() => onPress(num)} disabled={disabled}>
    {deleteMode ? <MdOutlineBackspace /> : num}
  </KeypadBtn>
);

const TimeSelector = ({ onSave, onClose, currentTime, max, min }: Props) => {
  const [active, setActive] = useState();
  const [maxDigits, setMaxDigits] = useState(DEFAULT_ACTIVE_DIGITS);
  const [minDigits, setMinDigits] = useState(0);
  const [timeObj, setTimeObject] = useState({
    h1: 0,
    h2: 0,
    m1: 0,
    m2: 0,
    s1: 0,
    s2: 0,
  });
  const [onlyOptionDigits, setOnlyOptionDigits] = useState([]);

  const MAX_TIMESTAMP = formatTimestamp(max, true);
  const MIN_TIMESTAMP = formatTimestamp(min, true);

  const onKeypadPress = (num) => {
    setTimeObject({
      ...timeObj,
      [active]: num,
    });

    // move to next field or submit if last field
    const idx = TIME_ORDER.indexOf(active);
    if (idx + 1 === TIME_ORDER.length) submitAndClose(num);
    else setActive(TIME_ORDER[idx + 1]);
  };

  const onDeletePress = () => {
    setTimeObject({
      ...timeObj,
      [active]: 0,
    });

    // move to previous field
    const idx = TIME_ORDER.indexOf(active);
    setActive(TIME_ORDER[idx - 1]);
  };

  const onFocus = (field) => {
    setActive(field);
  };

  const submitAndClose = (s2) => {
    // s2 represents that last field (seconds #2)
    // passing this so state refresh doesn't need to run
    const t = timeObj;
    const timestamp = `${t.h1}${t.h2}:${t.m1}${t.m2}:${t.s1}${s2}`;
    const newTime = timestampToSeconds(timestamp);
    onSave(newTime);
  };

  useEffect(() => {
    const initTimestamp = convertTimeToFields(currentTime);
    setTimeObject(initTimestamp);

    const initActive = (() => {
      if (max < 10) return 's2';
      else if (max < 60) return 's1';
      else if (max < 600) return 'm2';
      else if (max < 3600) return 'm1';
      else if (max < 36000) return 'h2';
      return 'h1';
    })();
    setActive(initActive);
  }, [currentTime, max]);

  const getActiveDigits = () => {
    const minFields = convertTimeToFields(min);
    const maxFields = convertTimeToFields(max);
    const activeIndex = TIME_ORDER.indexOf(active);

    let allPrevMaxed = true;
    let allPrevMined = true;
    let allZeroes = true;

    const d = (() => {
      if (active === 'h1') return maxFields.h1;

      // check if all previous fields are at max/min value (e.g. 1:42:37 vs. 1:42:3X)
      for (let i = 0; i < activeIndex; i++) {
        const fieldName = TIME_ORDER[i];
        const fieldVal = timeObj[fieldName];
        const maxVal = maxFields[fieldName];
        const minVal = minFields[fieldName];

        if (fieldVal > 0) allZeroes = false;
        if (fieldVal !== maxVal) allPrevMaxed = false;
        if (fieldVal !== minVal) allPrevMined = false;

        if (!allPrevMaxed && !allPrevMined) break;
      }

      // all maxed fields, match max number
      if (allPrevMaxed) {
        // if every prior number is not zero, set current to max value it can be
        if (!allZeroes) {
          setTimeObject({
            ...timeObj,
            [active]: maxFields[active],
          });
        }

        return maxFields[active];
      }

      // if first field in group, max at 5 (e.g 's1' and the five in 59 seconds)
      return active.charAt(1) === '1' ? 5 : DEFAULT_ACTIVE_DIGITS;
    })();

    // check if there's no need to input number (max and min are same = only one choice)
    if (allPrevMaxed && allPrevMined) {
      if (maxFields[active] === minFields[active]) {
        setOnlyOptionDigits([...onlyOptionDigits, active]);
        onKeypadPress(maxFields[active]);
      }
    }
    const MIN_DIGITS = allPrevMined ? minFields[active] : 0;
    setMinDigits(MIN_DIGITS);
    setMaxDigits(d);
  };

  useEffect(getActiveDigits, [active]);

  const isPreviousDisabled = (field) => {
    const idx = TIME_ORDER.indexOf(field);
    if (idx === 0) return true;

    const prevField = TIME_ORDER[idx - 1];
    return isDigitDisabled(prevField);
  };

  const isDigitDisabled = (field) => {
    if (onlyOptionDigits.includes(field)) return true;

    switch (field) {
      case 'h1':
        return max < 36000;
      case 'h2':
        return max < 3600;
      case 'm1':
        return max < 600;
      case 'm2':
        return max < 60;
      case 's1':
        return max < 10;
      default:
        return false;
    }
  };

  const PATTERN = max >= 3600 ? HOUR_PATTERN : MINUTE_PATTERN;

  return (
    <Container>
      <Content>
        <CloseBtn onClick={onClose}>
          <MdExpandMore />
        </CloseBtn>
        <HeadTxt>Enter Time</HeadTxt>
        {min > 0 ? (
          <BodyTxt>{`Use the fields below to enter your own custom timestamp between <b>${MIN_TIMESTAMP}</b> and <b>${MAX_TIMESTAMP}</b>`}</BodyTxt>
        ) : (
          <BodyTxt>{`Use the fields below to enter your own custom timestamp no greater than <b>${MAX_TIMESTAMP}</b>`}</BodyTxt>
        )}

        {timeObj ? (
          <>
            <InputRow>
              {PATTERN.map((item, index) =>
                item.type === 'digit' ? (
                  <DigitInput
                    key={item.field}
                    onFocus={onFocus}
                    field={item.field}
                    value={timeObj[item.field]}
                    active={active === item.field}
                    max={max}
                    disabled={isDigitDisabled(item.field)}
                  />
                ) : (
                  <InputRowTxt key={`text-${item.value}-${index}`}>
                    {item.value}
                  </InputRowTxt>
                ),
              )}
            </InputRow>
            <Keypad>
              <KeypadRow>
                <KeypadButton
                  onClick={onKeypadPress}
                  num={1}
                  disabled={minDigits > 1 || maxDigits < 1}
                />
                <KeypadButton
                  onClick={onKeypadPress}
                  num={2}
                  disabled={minDigits > 2 || maxDigits < 2}
                />
                <KeypadButton
                  onClick={onKeypadPress}
                  num={3}
                  disabled={minDigits > 3 || maxDigits < 3}
                />
              </KeypadRow>
              <KeypadRow>
                <KeypadButton
                  onClick={onKeypadPress}
                  num={4}
                  disabled={minDigits > 4 || maxDigits < 4}
                />
                <KeypadButton
                  onClick={onKeypadPress}
                  num={5}
                  disabled={minDigits > 5 || maxDigits < 5}
                />
                <KeypadButton
                  onClick={onKeypadPress}
                  num={6}
                  disabled={minDigits > 6 || maxDigits < 6}
                />
              </KeypadRow>
              <KeypadRow>
                <KeypadButton
                  onClick={onKeypadPress}
                  num={7}
                  disabled={minDigits > 7 || maxDigits < 7}
                />
                <KeypadButton
                  onClick={onKeypadPress}
                  num={8}
                  disabled={minDigits > 8 || maxDigits < 8}
                />
                <KeypadButton
                  onClick={onKeypadPress}
                  num={9}
                  disabled={minDigits > 9 || maxDigits < 9}
                />
              </KeypadRow>
              <KeypadRow>
                <EmptyKeypadItem>-</EmptyKeypadItem>
                <KeypadButton
                  onClick={onKeypadPress}
                  num={0}
                  disabled={minDigits > 0}
                />
                <KeypadButton
                  onClick={onDeletePress}
                  disabled={isPreviousDisabled(active)}
                  deleteMode
                />
              </KeypadRow>
            </Keypad>
          </>
        ) : null}
      </Content>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 500;
  background: ${(props) => props.theme.colors.primaryDarker};
  background: linear-gradient(
    0deg,
    ${(props) => props.theme.colors.primaryLight} 0%,
    ${(props) => props.theme.colors.primaryDarker} 100%
  );
  flex: 1;
`;

const Content = styled.div`
  position: relative;
  padding: 60px 20px 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CloseBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;

  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 10;

  svg {
    color: white;
    font-size: 40px;
  }
`;

const HeadTxt = styled.h3`
  color: white;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin: 20px 0;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.07);
`;

const BodyTxt = styled.p`
  color: white;
  font-size: 17px;
  font-weight: 400;
  text-align: center;
  line-height: 26px;
  padding: 0 20px;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.07);
  margin: 0 0 30px;
`;

const DigitWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin: 0 3px;
  justify-content: center;
  align-items: center;
`;

const DigitBtn = styled.button`
  background-color: rgba(255, 255, 255, 0.25);
  outline: none;
  border-radius: 10px;
  border: 3px solid;
  border-color: ${(props) =>
    props.active ? 'rgba(255,255,255,0.6)' : 'transparent'};

  color: white;
  font-size: 38px;
  text-align: center;
  font-weight: 700;
  width: 40px;
  height: 48px;

  ${({ disabled }) =>
    disabled &&
    `
		cursor: default;
		pointer-events: none;
	`}
`;

const DigitLabel = styled.label`
  color: white;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  opacity: 0.6;
  margin-top: 3px;
`;

const InputRow = styled.div`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const InputRowTxt = styled.span`
  color: white;
  font-size: 36px;
  padding-bottom: 16px;
`;

const Keypad = styled.div`
  margin-top: auto;
  padding: 20px;
  justify-content: center;
  align-items: center;
`;

const KeypadRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`;

const KeypadBtn = styled.button`
  background-color: transparent;
  border: 0;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  width: 56px;
  border-radius: 10px;
  margin: 5px 10px;

  color: white;
  font-size: 38px;
  font-weight: 300;

  &::active {
    background-color: rgba(255, 255, 255, 0.2);
  }

  svg {
    color: white;
    font-size: 28px;
    height: 28px;
  }

  ${({ disabled }) =>
    disabled &&
    `
		cursor: default;
		pointer-events: none;
	`}
`;

const EmptyKeypadItem = styled(KeypadBtn)`
  cursor: default;
  pointer-events: none;
`;

export default TimeSelector;
