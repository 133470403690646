import React, {
  forwardRef,
  useContext,
  useMemo,
  useState,
  useImperativeHandle,
} from 'react';
import styled from 'styled-components';
import { Range, getTrackBackground } from 'react-range';
import CreatorTimeControls from './CreatorTimeControls';
import CreatorTrimmerKnob from './CreatorTrimmerKnob';
import CreatorTrimmerKnobTooltip from './CreatorTrimmerKnobTooltip';
import TimeSelector from './TimeSelector';
import { CreatorContext } from '../../contexts/CreatorContext';
import { formatTimestamp } from '../../hooks/useTextHelper';
import {
  getMinTime,
  getMaxTime,
  onDecreaseTime,
  onIncreaseTime,
  checkDisabledBtns,
} from '../../hooks/useTrimmerHelper';
import { CREATOR_MIN_DURATION } from '../../../constants';

const CreatorTrimmer = (
  {
    videoRef,
    trimTimes,
    onSlideStart,
    onSlideComplete,
    onTimeChange,
    duration,
    trimmerBounds,
    timeSelectorDisabled,
    maxDuration,
    noLimit,
  },
  ref,
) => {
  const {
    state: { filmstrip },
  } = useContext(CreatorContext);

  const [activeHandle, setActiveHandle] = useState(0);
  const [showTooltip, setShowTooltip] = useState(false);
  const [currentTime, setCurrentTime] = useState(trimTimes);
  const [showTimeSelector, setShowTimeSelector] = useState(false);
  const [disabledBtns, setDisabledBtns] = useState([
    { max: false, min: false },
    { max: false, min: false },
  ]);

  // ***** CONSTANTS *****

  const TRIM_AREA_PCT = useMemo(() => {
    // calculate css left % for <TrimArea>
    const total = trimmerBounds[1] - trimmerBounds[0];
    const start =
      total <= 0 ? 0 : ((currentTime[0] - trimmerBounds[0]) / total) * 100;

    // calculate css width % for <TrimArea>
    const endDiff = trimmerBounds[1] - currentTime[1];
    const end = ((total - endDiff) / total) * 100 - start;

    return { start, end };
  }, [currentTime, trimmerBounds]);

  const MIN_TIME = useMemo(
    () => getMinTime(currentTime, maxDuration),
    [currentTime, maxDuration],
  );

  const MAX_TIME = useMemo(
    () => getMaxTime(true, duration, trimmerBounds, currentTime, maxDuration),
    [duration, trimmerBounds, currentTime, maxDuration],
  );

  const TIMESTAMP = useMemo(
    () => [formatTimestamp(currentTime[0]), formatTimestamp(currentTime[1])],
    [currentTime],
  );

  const TOOLTIP_POS = useMemo(() => {
    return activeHandle === 1
      ? TRIM_AREA_PCT.start + TRIM_AREA_PCT.end
      : TRIM_AREA_PCT.start;
  }, [TRIM_AREA_PCT, activeHandle]);

  // ******** SLIDER CONTROLS *********

  const onSlidingStart = () => {
    setShowTooltip(true);
    onSlideStart();
  };

  const onSlidingComplete = () => {
    setShowTooltip(false);

    onSlideComplete(activeHandle, currentTime[activeHandle]);

    // setTimeout(() => {
    //   setCurrentTime([...currentTime]);
    //   // force rerender
    //   multiRef.current = new Date().getTime();
    // }, 1000);
  };

  const updateTime = (handle, times, fromDrag, shouldNotUpdateParent) => {
    setCurrentTime(times);

    const disabled = checkDisabledBtns(
      true,
      trimTimes,
      trimmerBounds,
      duration,
      maxDuration,
      noLimit,
    );
    setDisabledBtns(disabled);

    // shouldNotUpdateParent used when video's capture control button is pressed
    //    this prevents from updating the video when the video was already updated
    // "handle" param passed from onMultiChange to tell TrimScreen which handle was moved
    if (!shouldNotUpdateParent) onTimeChange(times, handle, fromDrag);
  };

  // ******** TIME SELECTOR *********

  const openTimeSelector = (handle) => {
    if (videoRef?.current) videoRef.current.pause();
    setShowTimeSelector(true);
    setActiveHandle(handle);
  };

  const setTimeSelectorTime = (time) => {
    let arr = [...currentTime];
    arr[activeHandle] = time;
    updateTime(activeHandle, arr);
  };

  const closeTimeSelector = () => {
    if (videoRef?.current) videoRef.current.play();
    setShowTimeSelector(false);
  };

  // ******* MULTI SLIDER *******

  const onMultiChange = (values) => {
    // if somehow neither value changed, return
    if (values[0] === currentTime[0] && values[1] === currentTime[1]) return;

    // determine which handle was moved
    const handle = values[0] !== currentTime[0] ? 0 : 1;
    setActiveHandle(handle);

    let handleTime = values[handle];
    // moving handle beyond maxDuration, stop movable handle at maxDuration
    const currentRange = values[1] - values[0];
    if (handle === 0 && currentRange > maxDuration)
      handleTime = values[1] - maxDuration;
    else if (handle === 1 && currentRange > maxDuration)
      handleTime = values[0] + maxDuration;

    // moving handle beyond minDuration, stop movable handle at minDuration
    if (currentRange < CREATOR_MIN_DURATION) {
      handleTime =
        handle === 0
          ? values[1] - CREATOR_MIN_DURATION
          : values[0] + CREATOR_MIN_DURATION;
    }

    // replace value with any changes from these checks and send to TrimStartScreen
    let updatedValues = [...values];
    updatedValues[handle] = handleTime;
    updateTime(handle, updatedValues, true);
  };

  // ******** [+|-] BUTTONS *********

  const onDecrease = (btnIndex) => {
    const newTimes = onDecreaseTime(btnIndex, trimmerBounds, currentTime);
    updateTime(btnIndex, newTimes);
  };

  const onIncrease = (btnIndex) => {
    const newTimes = onIncreaseTime(
      true,
      btnIndex,
      duration,
      trimmerBounds,
      currentTime,
      maxDuration,
      noLimit,
    );
    updateTime(btnIndex, newTimes);
  };

  // when endPoint is updated by video controls, expose function to parent for updating slider
  useImperativeHandle(ref, () => ({
    updateSlider: (times) => {
      updateTime(1, times, false, true);
    },
  }));

  return (
    <Wrapper id="CreatorTrimmer">
      <TrimmerWrapper>
        <Filmstrip id="Filmstrip">
          {filmstrip
            ? filmstrip.map((thumb, id) => (
                <Thumbnail key={id} thumb={thumb.image} />
              ))
            : null}
        </Filmstrip>

        <TrimArea
          id="TrimArea"
          style={{
            left: `${TRIM_AREA_PCT.start}%`,
            width: `${TRIM_AREA_PCT.end}%`,
          }}
        />

        <CreatorTrimmerKnobTooltip
          show={showTooltip}
          handle={activeHandle}
          position={TOOLTIP_POS}>
          {formatTimestamp(currentTime[activeHandle], true)}
        </CreatorTrimmerKnobTooltip>

        <SliderWrapper>
          <Range
            draggableTrack={true}
            values={currentTime}
            step={0.1}
            min={trimmerBounds[0]}
            max={trimmerBounds[1]}
            onChange={onMultiChange}
            onFinalChange={onSlidingComplete}
            renderTrack={({ props, children }) => (
              <SelectedTrack
                ref={props.ref}
                onMouseDown={props.onMouseDown}
                onTouchStart={props.onTouchStart}
                style={{
                  width: '100%',
                  background: filmstrip
                    ? getTrackBackground({
                        values: trimTimes,
                        colors: [
                          'rgba(255, 255, 255, 0.6)',
                          'transparent',
                          'rgba(255, 255, 255, 0.6)',
                        ],
                        min: trimmerBounds[0],
                        max: trimmerBounds[1],
                      })
                    : 'transparent',
                  alignSelf: 'center',
                }}>
                {children}
              </SelectedTrack>
            )}
            renderThumb={({ props, index, isDragged }) => (
              <CreatorTrimmerKnob
                key={props.key}
                libProps={props}
                isDragged={isDragged}
                onDragStart={onSlidingStart}
                isStartKnob={index === 0}
                multiple
              />
            )}
          />
        </SliderWrapper>
      </TrimmerWrapper>

      <TimeControlsRow>
        <CreatorTimeControls
          mode={0}
          time={TIMESTAMP[0]}
          onDecrease={onDecrease}
          onIncrease={onIncrease}
          openTimeSelector={openTimeSelector}
          disabled={disabledBtns[0]}
          timeSelectorDisabled={timeSelectorDisabled}
        />

        {disabledBtns[1] ? (
          <CreatorTimeControls
            mode={1}
            time={TIMESTAMP[1]}
            onDecrease={onDecrease}
            onIncrease={onIncrease}
            openTimeSelector={openTimeSelector}
            disabled={disabledBtns[1]}
            timeSelectorDisabled={timeSelectorDisabled}
          />
        ) : null}
      </TimeControlsRow>

      {showTimeSelector && !timeSelectorDisabled ? (
        <TimeSelector
          onSave={setTimeSelectorTime}
          onClose={closeTimeSelector}
          currentTime={currentTime[activeHandle]}
          max={MAX_TIME[activeHandle]}
          min={MIN_TIME[activeHandle]}
        />
      ) : null}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 20px 20px 0 20px;

  @media only screen and (min-width: 768px) {
    padding: 20px 40px;
  }
`;

const TrimmerWrapper = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.backgroundColors.lightAlt};
  width: 100%;
  margin: 0 0 16px 0;

  @media only screen and (min-width: 768px) {
    margin: 40px 0;
  }
`;

const Filmstrip = styled.div`
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: flex-start;
  height: 4.5rem;
  overflow: hidden;
  width: 100%;

  @media only screen and (min-width: 768px) {
    height: 6rem;
  }

  @media only screen and (max-height: 570px) {
    height: 4rem;
  }
`;

const Thumbnail = styled.div`
  flex: 1;
  height: 100%;
  background-image: url(${(props) => props.thumb});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  user-select: none;
  pointer-events: none;
`;

const TrimArea = styled.div`
  position: absolute;
  top: -5%;
  z-index: 1;
  height: 110%;
  border-top: 6px solid ${(props) => props.theme.colors.primary};
  border-bottom: 6px solid ${(props) => props.theme.colors.primary};
  pointer-events: none;
`;

const SliderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const SelectedTrack = styled.div`
  position: absolute;
  height: 110%;
  transform: translateY(-5%);
`;

const TimeControlsRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export default forwardRef(CreatorTrimmer);
