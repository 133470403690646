import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';

const CreatorTrimmerKnobTooltip = ({
  show,
  handle,
  position = 0,
  center,
  children,
}) => {
  const isInitialMount = useRef(true);
  const [isAnimating, setIsAnimating] = useState(null);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    const direction = show ? 'in' : 'out';
    setIsAnimating(direction);
  }, [show]);

  return (
    <Tooltip
      animate={isAnimating}
      handle={handle}
      center={center}
      style={{ left: `${position}%` }}>
      {children}
    </Tooltip>
  );
};

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const Tooltip = styled.span`
  display: block;
  position: absolute;
  top: 0;
  background-color: ${props => props.theme.colors.primary};
  border-radius: 20px;
  padding: 6px 12px;
  margin-top: -12px;
  margin-left: ${props =>
    props.center ? 0 : props.handle === 0 ? '-20px' : '20px'};
  color: white;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  user-select: none;
  transform: translateX(
      ${props => (props.center ? '-50%' : props.handle === 0 ? '0%' : '-100%')}
    )
    translateY(-100%);
  animation-timing-function: ease-out;
  animation-duration: 500ms;
  animation-name: ${props =>
    props.animate === 'in'
      ? fadeIn
      : props.animated === 'out'
      ? fadeOut
      : 'none'};
  animation-fill-mode: forwards;
  opacity: 0;
`;

export default CreatorTrimmerKnobTooltip;
