// @flow
import React from 'react';
import styled from 'styled-components';

const ProBanner = () => <Banner>PRO</Banner>;

const Banner = styled.div`
  position: absolute;
  top: 5px;
  right: -30px;
  background-color: ${props => props.theme.colors.secondary};
  transform: rotate(45deg);
  justify-content: center;
  align-items: center;
  padding: 3px 30px;

  color: white;
  font-size: 9px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

export default ProBanner;
