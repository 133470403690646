import React from 'react';
import styled from 'styled-components';
import { MdOutlineFileUpload } from 'react-icons/md';
import ProBanner from './ProBanner';

const FileInput = ({ isPro, onChange, onUpgrade }) => (
  <Label htmlFor="x">
    <WebOnly>
      <span>
        <strong>Click to upload</strong>
        <MdOutlineFileUpload />
      </span>
      <p>or, drag and drop a file here</p>
    </WebOnly>
    <MobileOnly>
      <span>
        <strong>Tap to Upload File</strong>
      </span>
    </MobileOnly>
    {isPro ? (
      <input onChange={onChange} type="file" id="x" accept="video/*" />
    ) : (
      <>
        <UpgradeCoverBtn onClick={onUpgrade} />
        <ProBanner />
      </>
    )}
  </Label>
);

const WebOnly = styled.div`
  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

const MobileOnly = styled.div`
  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

const Label = styled.label`
  position: relative;
  border: 2px dashed ${(props) => props.theme.colors.border};
  border-radius: 15px;
  padding: 80px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: all 0.2s ease-in;
  background-color: white;
  cursor: pointer;
  width: 100%;
  max-width: 600px;
  box-sizing: border-box;
  overflow: hidden;

  &:hover {
    background-color: ${(props) => props.theme.backgroundColors.primary};
    border-color: ${(props) => props.theme.textColors.secondary};
  }

  input[type='file'] {
    display: none;
  }

  span {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    strong,
    svg {
      color: ${(props) => props.theme.textColors.secondary};
    }

    strong {
      font-size: 14px;
    }

    svg {
      font-size: 18px;
      margin-left: 8px;
    }
  }

  p {
    font-size: 12px;
    margin-bottom: 0;
    color: ${(props) => props.theme.textColors.secondary};
    font-weight: normal;
    text-transform: none;
  }
`;

const UpgradeCoverBtn = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  cursor: pointer;
`;

export default FileInput;
