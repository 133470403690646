import React, { useEffect } from 'react';
import styled from 'styled-components';

const CreatorTrimmerKnob = ({
  isDragged,
  onDragStart,
  onDragEnd,
  isStartKnob,
  libProps,
  multiple,
}) => {
  useEffect(() => {
    // only runs when dragging state changes, reports back to parent when drag starts/ends
    try {
      if (isDragged) onDragStart();
      else onDragEnd();
    } catch (err) {}
  }, [isDragged, onDragStart, onDragEnd]);

  return (
    <Knob
      {...libProps}
      style={{
        ...libProps.style,
        borderRadius: !multiple
          ? '10px'
          : isStartKnob
          ? '10px 0 0 10px'
          : '0 10px 10px 0',
      }}
    />
  );
};

const Knob = styled.span`
  width: 1.3rem;
  height: 100%;
  background-color: ${props => props.theme.colors.primary};
  display: flex;
  gap: 3px;
  align-items: center;
  justify-content: center;
  outline: none;

  &::after {
    content: '';
    width: 5px;
    background-color: #f2f2f2;
    border-radius: 100px;
  }
  &::after {
    height: 25%;
  }
`;

export default CreatorTrimmerKnob;
