import React from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';

const theme = {
  backgroundColors: {
    primary: '#F8F9F9',
    primaryRGB: '248,249,249',
    secondary: '#FFFFFF',
    secondaryRGB: '255,255,255',
    tertiary: '#F5F7FF',
    lightAlt: '#F5F7FF',
    lightAltRGB: '246,248,250',
    dark: '#222',
    darker: '#191919',
  },
  colors: {
    primary: '#3AA9E6',
    primaryLight: '#9CD4F2',
    primaryDark: '#319CE2',
    primaryDarker: '#288FDE',
    secondaryRGB: '252, 117, 105',
    secondary: '#FC7569',
    secondaryLight: '#FCA569',
    secondaryDark: '#EF685C',
    tertiary: '#FC7569',
    tertiaryRGB: '252,117,105',
    accent: '#FC7569',
    border: '#E8ECFA',
    borderRGB: '232,236,250',
    icon: '#8793A0',
    navy: '#363F49',
    green: '#47D381',
    yellow: '#FCCD68',
    blue: '#57B3FA',
    blueRGB: '87,179,250',
    blueLight: '#91D4F2',
    pink: '#F28C9C',
    purple: '#726AB9',
    gray: '#555',
    grayLight: '#E8ECED',
    warning: '#FF6152',
    tableHover: '#FAFCFF',
  },
  textColors: {
    primary: '#545C77',
    primaryRGB: '84,92,119',
    secondary: '#999FAF',
    secondaryRGB: '166,173,194',
    tertiary: '#9A9FAE',
    tertiaryRGB: '152,159,175',
  },
  fonts: {
    primary: "'PT Sans', sans-serif",
    secondary: "'Roboto', sans-serif",
  },
  fontWeights: {
    regular: 400,
    medium: 500,
    bold: 700,
  },
};

const GlobalStyles = createGlobalStyle`
  body {
    background-color: ${theme.backgroundColors.primary};
    margin: 0;
    font-family: ${theme.fonts.secondary};
  }
  div {
    box-sizing: border-box;
  }
  h1, h2 {
    font-family: ${theme.fonts.primary};
  }
`;

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyles />
    {children}
  </ThemeProvider>
);

export default Theme;
