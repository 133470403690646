import React, { useRef, useEffect, useContext, useState } from 'react';
import { init, setUserId } from '@amplitude/analytics-browser';
import * as Realm from 'realm-web';
import { SystemContext } from './contexts/SystemContext';
import CreatorPage from './pages/CreatorPage';
import { AMPLITUDE, APP_ID, WEBAPP_URL } from '../constants';

const realm = new Realm.App({
  id: APP_ID,
});
const ENV = window.location.hostname === 'localhost' ? 'dev' : 'prod';

const App = () => {
  const isInitialMount = useRef(true);
  const { dispatch: dispatchSystem } = useContext(SystemContext);

  const [loading, setLoading] = useState(true);

  // detect if in webview (ios only right now)
  useEffect(() => {
    const navigateBackToWebApp = () => {
      const url = `${WEBAPP_URL[ENV]}/login?ref=creator`;
      window.location = url;
    };

    const isInWebView = () => {
      const standalone = window.navigator.standalone;
      const userAgent = window.navigator.userAgent.toLowerCase();
      const safari = /safari/.test(userAgent);
      const ios = /iphone|ipod|ipad/.test(userAgent);

      return ios && !safari && !standalone;
    };

    const authenticateUser = async (key) => {
      try {
        const credentials = Realm.Credentials.apiKey(key);

        // Authenticate the user
        await realm.logIn(credentials);
        await realm.currentUser.refreshCustomData();

        return realm.currentUser;
      } catch (err) {
        console.log('Error on Init - redirecting to login: ', err);
        // credentials are invalid, redirect to auth
        navigateBackToWebApp();
        return;
      }
    };

    const onInit = async () => {
      // 1. Verify Auth Key is present

      /**
       * User can enter creator from app or website.
       * Consider that user from app may have a different account than
       * what is sessioned on web. If present, always use 'key' as account of record.
       */

      // check URL params
      const urlParams = new URLSearchParams(window.location.search);
      const key = urlParams.get('key');

      // no key detected => take to webapp login
      if (!key) {
        navigateBackToWebApp();
        return;
      }

      // 2. Authenticate User
      const realmUser = await authenticateUser(key);

      // 3. Initialize Amplitude
      init(AMPLITUDE[ENV]);
      setUserId(realmUser.id);

      // 4. Set session variables
      const inWebView = isInWebView();
      dispatchSystem({
        type: 'SET_SYSTEM',
        data: {
          inWebView,
          deepLinkURL: urlParams.get('v'),
          fromApp: urlParams.get('ref') === 'app',
          realmUser,
          isPro: realmUser?.customData?.subscription?.isValid,
        },
      });

      // Ready! Turn off loading and launch Creator
      setLoading(false);
    };

    if (isInitialMount.current) {
      isInitialMount.current = false;

      onInit();
    }
  }, [dispatchSystem]);

  return loading ? null : <CreatorPage />;
};

export default App;
