import React, { useContext, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { fetchFile } from '@ffmpeg/ffmpeg';
import { MdCheck, MdChevronLeft } from 'react-icons/md';
import LoaderWithText from '../common/LoaderWithText';
import CreatorHeader from './CreatorHeader';
import CreatorStartPoint from './CreatorStartPoint';
import CreatorTrimmer from './CreatorTrimmer';
import CreatorVideo from './CreatorVideo';
import { CreatorContext } from '../../contexts/CreatorContext';
import { toTimeString } from '../../hooks/useTextHelper';
import { readFileAsBase64 } from '../../hooks/useVideoHelper';
import { CREATOR_MAX_DURATION } from '../../../constants';

const HEADER_CONFIG = {
  start: {
    title: 'Select Start Point',
    btnLeftIcon: <MdChevronLeft />,
  },
  trim: {
    title: 'Trim Clip',
    btnLeftIcon: <MdChevronLeft />,
  },
  final: {
    title: 'Finalize & Save',
    btnLeftIcon: <MdChevronLeft />,
  },
};

const CreatorClipping = ({
  ffmpeg,
  isPro,
  onFFMPEGReady,
  onFFMPEGError,
  onBack,
}) => {
  const isInitialLoad = useRef(true);
  const trimmerRef = useRef(null);
  const videoRef = useRef(null);
  const ffmpegProgressRef = useRef(null);

  const {
    state: { file, filename, url, editMode, startTime, endTime },
    dispatch: dispatchCreator,
  } = useContext(CreatorContext);

  const MAX_DURATION = CREATOR_MAX_DURATION[isPro ? 'pro' : 'free'];

  const [ready, setReady] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState('Loading video');
  // if endTime already exists, user is navigating back to trim mode, init in trim
  const [trimMode, setTrimMode] = useState(endTime ? 'trim' : 'start');

  const [trimmerBounds, setTrimmerBounds] = useState();
  // if startTime/endTime both exist, user is navigating back to trim mode
  const [trimTimes, setTrimTimes] = useState(
    startTime && endTime ? [Number(startTime), Number(endTime)] : [0, 0],
  );
  const [meta, setMeta] = useState();

  const onClear = () => {
    dispatchCreator({ type: 'CLEAR' });
    onBack();
  };

  const onBackClick = () => {
    if (trimMode === 'start') {
      onClear();
    } else if (trimMode === 'trim') {
      videoRef.current.pause();
      setTrimMode('start');
    }
  };

  const getTrimmerBounds = async (duration, start, end, mode) => {
    // Setup trimmer bar with appropriate range limits
    // Assumes that:
    // 	1. start point has been selected
    //	2. duration is longer than max duration
    // add 3 seconds of wiggle room to each edge of trimmer
    // ^ enables view to "zoom" on selectable area with 3s of adjustment beyond initially selected start time

    const DURATION = parseFloat(duration);
    const BUFFER = 3;
    const LOWER = start - BUFFER < 0 ? 0 : start - BUFFER;
    const MAX_BOUND_RANGE = LOWER + MAX_DURATION + BUFFER * 2;
    const EDIT_MAX = !editMode
      ? null
      : end + BUFFER < DURATION
      ? end + BUFFER
      : DURATION;
    const UPPER =
      mode === 'start'
        ? DURATION
        : editMode
        ? EDIT_MAX // if edit mode, use end time + buffer
        : MAX_BOUND_RANGE > DURATION // otherwise use duration of video
        ? DURATION
        : MAX_BOUND_RANGE;

    setTrimmerBounds([LOWER, UPPER]);

    if (endTime) {
      // if endTime exists, we're navigating back and filmstrip already exists
      setLoading();
      videoRef.current.play();
    } else {
      // generate new filmstrip from bounds
      getFilmstrip(LOWER, mode === 'trim' ? UPPER - LOWER : DURATION, end);
    }
  };

  const getFilmstrip = async (start, duration, end) => {
    if (!ffmpeg.isLoaded()) await ffmpeg.load();

    const NUMBER_OF_IMAGES = 8;
    const OFFSET = duration / NUMBER_OF_IMAGES;

    ffmpegProgressRef.current = 'running';

    try {
      let arrayOfImageURIs = [];
      ffmpeg.FS('writeFile', filename, await fetchFile(file));

      for (let i = 0; i < NUMBER_OF_IMAGES; i++) {
        if (isInitialLoad.current)
          setLoading(`Preparing Creator ${i + 1}/${NUMBER_OF_IMAGES}`);

        let time = Math.round((i * OFFSET + OFFSET / 2 + start) * 100) / 100;
        if (time > duration) time = duration;
        const startTimeInSecs = toTimeString(time);

        try {
          await ffmpeg.run(
            '-ss',
            startTimeInSecs,
            '-i',
            filename,
            '-t',
            '00:00:1.000',
            '-vf',
            `scale=150:-1`,
            `img${i}.png`,
          );
          const data = ffmpeg.FS('readFile', `img${i}.png`);

          const blob = new Blob([data.buffer], { type: 'image/png' });
          const dataURI = await readFileAsBase64(blob);
          ffmpeg.FS('unlink', `img${i}.png`);

          const thumbObj = {
            time: startTimeInSecs,
            image: dataURI,
          };
          arrayOfImageURIs.push(thumbObj);
        } catch (err) {
          console.log(
            'Error processing thumnail',
            startTimeInSecs,
            err.message,
          );
        }
      }

      dispatchCreator({
        type: 'SET_CREATOR',
        data: { filmstrip: arrayOfImageURIs },
      });
    } catch (err) {
      console.log('Error processing filmstrip: ', err);
    } finally {
      if (ffmpegProgressRef.current === 'waitingToTrim') onTrim(end);
      else {
        setLoading();
        // autoplay video after initial load
        videoRef.current.play();
        ffmpegProgressRef.current = '';
      }

      isInitialLoad.current = false;
    }
  };

  const onSelectStartPoint = () => {
    const _endTime =
      meta.duration > trimTimes[0] + MAX_DURATION
        ? trimTimes[0] + MAX_DURATION
        : meta.duration;

    getTrimmerBounds(meta.duration.toFixed(1), trimTimes[0], _endTime, 'trim');

    setTrimTimes([trimTimes[0], Number(_endTime.toFixed(1))]);
    setTrimMode('trim');

    videoRef.current.currentTime = trimTimes[0];
  };

  const onTrim = async (trimEnd) => {
    // trimEnd is only passed as security for rare use case:
    // when user speeds through process and trimTimes[1] is stuck in a race condition
    videoRef.current.pause();
    setLoading('Trimming video');

    if (ffmpegProgressRef.current === 'running') {
      ffmpegProgressRef.current = 'waitingToTrim';
      return;
    }

    const startTime = trimTimes[0].toFixed(2);
    const endTime = trimTimes[1] || trimEnd;
    const offset = (endTime - startTime).toFixed(2);
    console.log(
      'meta.duration: ',
      meta.duration,
      '   endTime: ',
      endTime,
      '   startTime: ',
      startTime,
      '   offset: ',
      offset,
      '   filename: ',
      filename,
    );

    try {
      ffmpeg.FS('writeFile', filename, await fetchFile(file));
      // await ffmpeg.run('-ss', '00:00:13.000', '-i', filename, '-t', '00:00:5.000', 'ping.mp4');
      await ffmpeg.run(
        '-ss',
        toTimeString(startTime),
        '-i',
        filename,
        '-t',
        toTimeString(offset),
        '-c',
        'copy',
        'ping.mp4',
      );

      const data = ffmpeg.FS('readFile', 'ping.mp4');
      const dataURL = await readFileAsBase64(
        new Blob([data.buffer], { type: 'video/mp4' }),
      );

      // save in context for server
      dispatchCreator({
        type: 'SET_CREATOR',
        data: {
          trimmedVideo: dataURL,
          startTime: Number(startTime),
          endTime: Number(endTime),
          duration: offset,
        },
      });
    } catch (err) {
      console.log('Error trimming video: ', err);
      setLoading();
    }
  };

  const onVideoLoad = (metaInfo) => {
    console.log('metaInfo: ', metaInfo);
    setMeta({ ...metaInfo, name: filename });
    dispatchCreator({
      type: 'SET_CREATOR',
      data: {
        videoWidth: metaInfo.videoWidth,
        videoHeight: metaInfo.videoHeight,
      },
    });

    // simulate seeking while we get trimmer setup
    videoRef.current.pause();

    if (endTime) {
      // navigating back from details to trim mode, reload settings
      getTrimmerBounds(
        metaInfo.duration.toFixed(1),
        startTime,
        endTime,
        'trim',
      );
    } else {
      const _endTime =
        metaInfo.duration > trimTimes[0] + MAX_DURATION
          ? trimTimes[0] + MAX_DURATION
          : metaInfo.duration;

      const _trimMode = metaInfo.duration > MAX_DURATION ? 'start' : 'trim';
      setTrimMode(_trimMode);

      setTrimTimes([trimTimes[0], Number(_endTime.toFixed(1))]);

      // kick off boundary limits and thumbnail creation
      getTrimmerBounds(
        metaInfo.duration.toFixed(1),
        trimTimes[0],
        _endTime,
        _trimMode,
      );
    }
  };

  const onVideoError = () => {
    setError({ message: 'Unable to load video.' });
  };

  // -------- TRIMMER CONTROLS --------

  const onSlideComplete = (handle, time) => {
    const seekTime = getAdjustedPreviewTime(handle, time);
    videoRef.current.currentTime = seekTime.toString();
    videoRef.current.play();

    const newTimes = [...trimTimes];
    newTimes[handle] = time;
    setTrimTimes(newTimes);
  };

  const getAdjustedPreviewTime = (handle, time) => {
    // seek to preview time (on end handle, start video just inside of handle's time)
    const END_BUFFER = 1;
    let seekTime = handle === 0 ? time : time - END_BUFFER;

    // if end handle moved and trimmed clip is less than 2 seconds, play whole clip
    if (handle === 1 && seekTime < trimTimes[0]) seekTime = trimTimes[0];

    return seekTime;
  };

  const onTimeChange = (times, handle, fromDrag) => {
    // fail safe, never allow time to be greater than duration
    const newTimes =
      times[1] > meta.duration ? [times[0], meta.duration] : times;
    setTrimTimes(newTimes);

    // if dragging, onSlideComplete sets final seek time correctly
    // otherwise: decimal button or timeSelector updated and adjusted preview time is needed
    const seekTime = fromDrag
      ? times[handle]
      : getAdjustedPreviewTime(handle, times[handle]);

    videoRef.current.currentTime = seekTime.toString();
    if (!fromDrag) videoRef.current.play();
  };

  const onCaptureTime = (endTime) => {
    // fired when clicking stop indicator in bottom right while video is playing
    const newTimes = [trimTimes[0], endTime];
    setTrimTimes(newTimes);
    trimmerRef.current.updateSlider(newTimes);
  };

  // <CreatorFileSelect /> does not use ffmpeg, so this is likely the first spot to load it
  // ffmpeg is passed from <CreatorPage> down to child components
  // use ffmpeg.isLoaded() as a check before running any functions on ffmpeg
  useEffect(() => {
    const loadFFMPEG = async () => {
      if (!ffmpeg.isLoaded()) {
        try {
          await ffmpeg.load();
          setReady(true);
          onFFMPEGReady(true);
        } catch (err) {
          console.log('Error: ', err);
          onFFMPEGError();
        }
      } else {
        setError();
        setReady(true);
        onFFMPEGReady(true);
      }
    };

    loadFFMPEG();
  }, [onFFMPEGReady, onFFMPEGError, ffmpeg]);

  return ready ? (
    <Container id="CreatorClippingContainer">
      {loading ? null : (
        <CreatorHeader {...HEADER_CONFIG[trimMode]} onBack={onBackClick} />
      )}
      <>
        {error ? null : (
          <VideoWrapper>
            <CreatorVideo
              ref={videoRef}
              src={url}
              startTime={trimTimes[0]}
              playbackBounds={trimTimes}
              onLoad={onVideoLoad}
              onError={onVideoError}
              onCaptureTime={trimMode === 'trim' ? onCaptureTime : null}
              trimMode={trimMode}
            />
          </VideoWrapper>
        )}

        {error ? (
          <LoaderWithText paddingTop="48px" error onRetry={onClear}>
            {error?.message}
          </LoaderWithText>
        ) : loading ? (
          <LoaderWithText>{loading}</LoaderWithText>
        ) : (
          <>
            {trimMode === 'start' ? (
              <CreatorStartPoint
                videoRef={videoRef}
                trimmerBounds={trimmerBounds}
                startTime={trimTimes[0]}
                onSlideStart={() => videoRef.current.pause()}
                onSlideComplete={onSlideComplete}
                onTimeChange={onTimeChange}
                duration={meta?.duration}
                timeSelectorDisabled={true} //meta?.duration < 2.1}
                maxDuration={MAX_DURATION}
              />
            ) : (
              <CreatorTrimmer
                ref={trimmerRef}
                videoRef={videoRef}
                trimmerBounds={trimmerBounds}
                trimTimes={trimTimes || [0, 0]}
                onSlideStart={() => videoRef.current.pause()}
                onSlideComplete={onSlideComplete}
                onTimeChange={onTimeChange}
                duration={meta?.duration}
                timeSelectorDisabled={true} //meta?.duration < 2.1}
                maxDuration={MAX_DURATION}
              />
            )}

            <Button onClick={trimMode === 'trim' ? onTrim : onSelectStartPoint}>
              <MdCheck />
            </Button>
          </>
        )}
      </>
    </Container>
  ) : null;
};

const Container = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
`;

const VideoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 212px;
  width: 100%;
  background-color: ${(props) => props.theme.backgroundColors.primary};

  @media only screen and (max-width: 375px) {
    height: 180px;
  }

  @media only screen and (min-width: 768px) {
    height: 360px;
  }

  @media only screen and (max-height: 570px) {
    height: 150px;
  }
`;

const Button = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.primary};
  height: 72px;
  width: 72px;
  outline: 0;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  margin: auto 0 20px;

  svg {
    color: white;
    font-size: 40px;
  }

  &:active {
    background-color: rgba(${(props) => props.theme.colors.primaryRGB}, 0.7);
  }

  @media only screen and (min-width: 768px) {
    margin: auto 0 40px;
  }

  @media only screen and (max-height: 570px) {
    height: 60px;
    width: 60px;

    svg {
      font-size: 36px;
    }
  }
`;

export default CreatorClipping;
