import React, { useContext, useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import isURL from 'validator/lib/isURL';
import {
  FaFacebookF,
  FaInstagram,
  FaTiktok,
  FaTwitter,
  FaYoutube,
  FaTimesCircle,
} from 'react-icons/fa';
import FileInput from './FileInput';
import { CreatorContext } from '../../contexts/CreatorContext';
import useAmplitude from '../../hooks/useAmplitude';
import { readFileAsBase64 } from '../../hooks/useVideoHelper';
import ActivityIndicator from '../common/ActivityIndicator';
import logo from '../../../assets/images/logo.svg';
import { getSourceName } from '../../hooks/useTextHelper';
import { WEBAPP_URL, REMOVE_SOCIAL } from '../../../constants';
import { SystemContext } from '../../contexts/SystemContext';

const CREATOR_URL = 'https://video-src-extractor.vercel.app/api/extract?url=';
const STREAM_URL = 'https://gentle-bonus-b383.clipbox.workers.dev?url=';

const ENV = window.location.hostname === 'localhost' ? 'dev' : 'prod';

const CreatorFileSelect = ({ isPro, onUpgrade, deepLinkURL }) => {
  const { trackEvent } = useAmplitude();

  const {
    state: { url },
    dispatch: dispatchCreator,
  } = useContext(CreatorContext);
  const {
    state: { inWebView, fromApp },
  } = useContext(SystemContext);

  const [sourceURL, setSourceURL] = useState(
    deepLinkURL ? decodeURIComponent(deepLinkURL) : url || '',
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const fetchVideoFromURL = useCallback(
    async (url) => {
      const metricProps = {
        Source: getSourceName(url),
        'Source URL': url,
      };

      try {
        // if (url.includes('youtu')) throw new Error('YouTube Video');

        const res = await fetch(`${CREATOR_URL}${encodeURIComponent(url)}`);
        const data = await res.json();

        if (data?.errors?.length > 0)
          throw new Error(
            `${data.errors[0]?.label}: ${data.errors[0]?.message}`,
          );

        dispatchCreator({
          type: 'SET_CREATOR',
          data: {
            file: `${STREAM_URL}${encodeURIComponent(data.path)}`,
            filename: `${data.sourceId}.mp4`,
            url: `${STREAM_URL}${encodeURIComponent(data.path)}`,
            sourceURL: url,
          },
        });

        trackEvent('Creator Link Video Retrieved', metricProps);
      } catch (err) {
        console.log('Error fetching video: ', err);
        trackEvent('Creator Link Video Failed', metricProps);
        setError('Unable to retrieve video');
        setLoading(false);
      }
    },
    [dispatchCreator],
  );

  const checkURL = useCallback(
    (url, isDeepLink) => {
      if (loading || !url) return;

      const isLink = isURL(url);
      if (isLink) {
        setError(null);
        setLoading(true);

        trackEvent('Creator Link Processing', {
          Source: getSourceName(url),
          'Source URL': url,
          'Deep Link': isDeepLink ? 'Yes' : 'No',
        });
        fetchVideoFromURL(url);
      } else {
        setError('Invalid URL');
      }
    },
    [fetchVideoFromURL],
  );

  const onFileUpload = async (e) => {
    if (!isPro) {
      setError('Upgrade to PRO to use this feature.');
      return;
    }
    setLoading(true);

    const file = e.target.files[0];

    try {
      const url = await readFileAsBase64(file);
      dispatchCreator({
        type: 'SET_CREATOR',
        data: {
          file,
          filename: file.name,
          url,
        },
      });
    } catch (err) {
      console.log('FileReader error: ', err);
      setError('Unable to read this file');
      setLoading(false);
    }
  };

  useEffect(() => {
    // if deepLink passed in, go ahead and process immediately
    if (deepLinkURL) checkURL(decodeURIComponent(deepLinkURL), true);
  }, [deepLinkURL, checkURL]);

  const onClear = () => {
    setSourceURL('');
    setError();
  };

  return (
    <Container>
      {loading ? (
        <LoadingWrapper>
          <p>Fetching video from server</p>
          <ActivityIndicator color="secondary" />
        </LoadingWrapper>
      ) : (
        <ContentWrapper>
          <LogoLink
            href={inWebView || fromApp ? '#' : WEBAPP_URL[ENV]}
            disabled={inWebView || fromApp}>
            <Logo src={logo} />
          </LogoLink>
          <Content>
            <FileInput
              isPro={isPro}
              onChange={onFileUpload}
              onUpgrade={onUpgrade}
            />
            <TextInputWrapper>
              <TextInput
                id="url"
                value={sourceURL}
                placeholder="Paste your video URL"
                onChange={(e) => setSourceURL(e.target.value)}
                onBlur={(e) => checkURL(e.target.value)}
                onPaste={(e) => checkURL(e.clipboardData.getData('Text'))}
                showClear={sourceURL?.length > 0}
              />
              {sourceURL?.length > 0 ? (
                <ClearBtn onClick={onClear}>
                  <FaTimesCircle />
                </ClearBtn>
              ) : null}
              <ErrorMsg>{error}</ErrorMsg>
            </TextInputWrapper>
            {REMOVE_SOCIAL ? null : (
              <SupportedApps>
                <p>Supported Apps:</p>
                <Icons>
                  <FaYoutube />
                  <FaFacebookF />
                  <FaInstagram />
                  <FaTwitter />
                  <FaTiktok />
                </Icons>
              </SupportedApps>
            )}
          </Content>
        </ContentWrapper>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex: 1;

  @media only screen and (max-width: 767px) {
    padding: 20px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 1;
`;

const Content = styled.div`
  max-width: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const LogoLink = styled.a`
  margin-bottom: 36px;
  justify-self: flex-start;
  cursor: pointer;

  @media only screen and (min-width: 768px) {
    margin-top: 30px;
  }

  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
  `}
`;

const Logo = styled.img`
  width: 120px;

  @media only screen and (min-width: 768px) {
    width: 150px;
  }
`;

const SupportedApps = styled.div`
  display: block;
  text-align: center;
  padding-top: 30px;

  p {
    font-size: 13px;
    color: ${(props) => props.theme.textColors.secondary};
    margin-bottom: 20px;
  }
`;

const Icons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  svg {
    font-size: 18px;
    color: ${(props) => props.theme.textColors.secondary};
    opacity: 0.7;
    margin: 0 8px;
  }
`;

const TextInputWrapper = styled.div`
  position: relative;
  min-height: 120px;
  width: 100%;
`;

const TextInput = styled.input`
  width: 100%;
  margin-top: 20px;

  ${({ showClear }) =>
    showClear &&
    `
    padding-right: 44px;
  `}
`;

const ClearBtn = styled.span`
  position: absolute;
  top: 24px;
  right: 0;
  display: flex;
  cursor: pointer;
  width: 44px;
  height: 44px;
  justify-content: center;
  align-items: center;

  svg {
    font-size: 24px;
    color: ${(props) => props.theme.textColors.secondary};
    opacity: 0.4;
  }
`;

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    font-size: 12px;
    color: ${(props) => props.theme.textColors.secondary};
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.2px;
  }
`;

const ErrorMsg = styled.p`
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.2px;
  color: ${(props) => props.theme.colors.warning};
  margin-top: 10px;
  text-align: center;
`;

export default CreatorFileSelect;
