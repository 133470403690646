import React from 'react';
import ReactDOM from 'react-dom/client';
import Root from './App/Root';
import Shell from './App/Shell';
import Theme from './App/Theme';
import GlobalStyle from './globalStyles';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Theme>
      <GlobalStyle />
      <Root>
        <Shell />
      </Root>
    </Theme>
  </React.StrictMode>,
);
