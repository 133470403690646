import {
  track,
  Identify,
  identify,
  setUserId,
} from '@amplitude/analytics-browser';

const useAmplitude = () => {
  const trackEvent = (eventName, properties) => {
    track(eventName, { Client: 'Web', ...properties });
  };

  const setUserProperty = (key, value) => {
    const identifyObj = new Identify();
    identifyObj.set(key, value);
    identify(identifyObj);
  };

  const trackRegisteredUser = (authType, user) => {
    try {
      track('User Registered', { 'Registered Using': authType });
      setUserId(user?.id);
      identify({
        'First Name': user?.firstName,
        'Last Name': user?.lastName,
        Email: user?.email,
        'Clips Created': 0,
        'Playlists Created': 0,
        'Pro Subscription': 'None',
      });
    } catch (error) {
      console.log('trackRegisteredUser Error: ', error);
    }
  };

  const trackLogin = async (authType, user) => {
    try {
      track('User Logged In', { 'Registered Using': authType });
      setUserId(user?.id);
      identify({
        'First Name': user?.firstName,
        'Last Name': user?.lastName,
        Email: user?.email,
      });
    } catch (error) {
      console.log('trackLogin Error: ', error);
    }
  };

  return { trackEvent, trackLogin, trackRegisteredUser, setUserProperty };
};

export default useAmplitude;
