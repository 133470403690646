import React, { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Range } from 'react-range';
import CreatorTimeControls from './CreatorTimeControls';
import CreatorTrimmerKnob from './CreatorTrimmerKnob';
import CreatorTrimmerKnobTooltip from './CreatorTrimmerKnobTooltip';
import TimeSelector from './TimeSelector';
import { CreatorContext } from '../../contexts/CreatorContext';
import { formatTimestamp } from '../../hooks/useTextHelper';
import {
  getMaxTime,
  onDecreaseTime,
  onIncreaseTime,
  checkDisabledBtns,
} from '../../hooks/useTrimmerHelper';

const CREATOR_MIN_DURATION = 1;

const CreatorStartPoint = ({
  videoRef,
  startTime,
  onSlideStart,
  onSlideComplete,
  onTimeChange,
  duration,
  trimmerBounds,
  timeSelectorDisabled,
  maxDuration,
  noLimit,
}) => {
  const {
    state: { filmstrip },
  } = useContext(CreatorContext);

  const [activeHandle, setActiveHandle] = useState(0);
  const [showTooltip, setShowTooltip] = useState(false);
  const [currentTime, setCurrentTime] = useState([startTime]);
  const [showTimeSelector, setShowTimeSelector] = useState(false);
  const [disabledBtns, setDisabledBtns] = useState([{ max: false, min: true }]);

  // ***** CONSTANTS *****

  const TRIM_AREA_START_PCT = useMemo(() => {
    return (currentTime[0] / duration) * 100;
  }, [currentTime, duration]);

  const MAX_TIME = useMemo(
    () => getMaxTime(false, duration, trimmerBounds, currentTime, maxDuration),
    [duration, trimmerBounds, currentTime, maxDuration],
  );
  const TIMESTAMP = useMemo(() => [formatTimestamp(currentTime[0])], [
    currentTime,
  ]);

  // ******** SLIDER CONTROLS *********

  const onSlidingStart = () => {
    setShowTooltip(true);
    onSlideStart();
  };

  const onSlidingComplete = () => {
    setShowTooltip(false);

    onSlideComplete(0, currentTime[0]);
  };

  const onSliderChange = values => {
    videoRef.current.muted = false;

    let updatedValue = values[0] || 0;

    if (updatedValue > duration - CREATOR_MIN_DURATION) {
      updatedValue = duration - CREATOR_MIN_DURATION;
    }

    updateTime(0, [updatedValue], true);
  };

  const updateTime = (handle, times, fromDrag) => {
    setCurrentTime(times);

    const disabled = checkDisabledBtns(
      false,
      times,
      trimmerBounds,
      duration,
      maxDuration,
      noLimit,
    );
    setDisabledBtns(disabled);

    onTimeChange(times, handle, fromDrag);
  };

  // ******** TIME SELECTOR *********

  const openTimeSelector = handle => {
    if (videoRef?.current) videoRef.current.pause();
    setShowTimeSelector(true);
    setActiveHandle(handle);
  };

  const setTimeSelectorTime = time => {
    let arr = [...currentTime];
    arr[activeHandle] = time;
    updateTime(activeHandle, arr);
  };

  const closeTimeSelector = () => {
    if (videoRef?.current) videoRef.current.play();
    setShowTimeSelector(false);
  };

  // ******** [+|-] BUTTONS *********

  const onDecrease = btnIndex => {
    const newTimes = onDecreaseTime(btnIndex, trimmerBounds, currentTime);
    updateTime(btnIndex, newTimes);
  };

  const onIncrease = btnIndex => {
    const newTimes = onIncreaseTime(
      false,
      btnIndex,
      duration,
      trimmerBounds,
      currentTime,
      maxDuration,
      noLimit,
    );
    updateTime(btnIndex, newTimes);
  };

  return (
    <Wrapper id="StartPointTrimmer">
      <TrimmerWrapper>
        <Filmstrip id="Filmstrip">
          {filmstrip
            ? filmstrip.map((thumb, id) => (
                <Thumbnail key={id} thumb={thumb.image} />
              ))
            : null}
        </Filmstrip>

        <CreatorTrimmerKnobTooltip
          show={showTooltip}
          handle={0}
          position={TRIM_AREA_START_PCT}>
          {formatTimestamp(currentTime[0], true)}
        </CreatorTrimmerKnobTooltip>

        <SliderWrapper>
          <Range
            values={currentTime}
            step={0.1}
            min={0}
            max={duration}
            onChange={onSliderChange}
            onFinalChange={onSlidingComplete}
            renderTrack={({ props, children }) => (
              <SelectedTrack
                ref={props.ref}
                onMouseDown={props.onMouseDown}
                onTouchStart={props.onTouchStart}
                style={{
                  width: '100%',
                  background: `linear-gradient(to right, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.6) ${TRIM_AREA_START_PCT}%, transparent ${TRIM_AREA_START_PCT}%, transparent 100%)`,
                  alignSelf: 'center',
                }}>
                {children}
              </SelectedTrack>
            )}
            renderThumb={({ props, index, isDragged }) => (
              <CreatorTrimmerKnob
                key={props.key}
                libProps={props}
                isDragged={isDragged}
                onDragStart={onSlidingStart}
                isStartKnob={true}
              />
            )}
          />
        </SliderWrapper>
      </TrimmerWrapper>

      <TimeControlsRow>
        <CreatorTimeControls
          mode={0}
          time={TIMESTAMP[0]}
          onDecrease={onDecrease}
          onIncrease={onIncrease}
          openTimeSelector={openTimeSelector}
          disabled={disabledBtns[0]}
          timeSelectorDisabled={timeSelectorDisabled}
        />
      </TimeControlsRow>

      {showTimeSelector && !timeSelectorDisabled ? (
        <TimeSelector
          onSave={setTimeSelectorTime}
          onClose={closeTimeSelector}
          currentTime={currentTime[activeHandle]}
          max={MAX_TIME[activeHandle]}
          min={0}
        />
      ) : null}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 20px 20px 0 20px;

  @media only screen and (min-width: 768px) {
    padding: 20px 40px;
  }
`;

const TrimmerWrapper = styled.div`
  position: relative;
  background-color: ${props => props.theme.backgroundColors.primary};
  width: 100%;
  margin: 0 0 16px 0;

  @media only screen and (min-width: 768px) {
    margin: 40px 0;
  }
`;

const Filmstrip = styled.div`
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: flex-start;
  height: 4.5rem;
  overflow: hidden;
  width: 100%;

  @media only screen and (min-width: 768px) {
    height: 6rem;
  }
`;

const Thumbnail = styled.div`
  flex: 1;
  height: 100%;
  background-image: url(${props => props.thumb});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  user-select: none;
  pointer-events: none;
`;

const SliderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const SelectedTrack = styled.div`
  position: absolute;
  height: 110%;
  transform: translateY(-5%);
`;

const TimeControlsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
`;

export default CreatorStartPoint;
