// @flow
import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import Modal from './Modal';
import { MdClose } from 'react-icons/md';
import { FiTrash2 } from 'react-icons/fi';
import {
  reduceToTagFormat,
  findObjectInArray,
} from '../../hooks/useTextHelper';
import PredictiveTagsRow from '../creator/PredictiveTagsRow';

const MINIMUM_TAG_LENGTH = 3;
const TAGS_ROW_HEIGHT = 275;
const EMPTY_MSG_PRO = 'Add tags above to improve your search experience';
const EMPTY_MSG_FREE =
  'Upgrade to PRO for unlimited clip creation and use tags to quickly search and find clips in your library';

const TagsModal = ({ isVisible, onModalClose, initTags, isPro }) => {
  const tagRef = useRef(null);
  const commaCleared = useRef(false);

  const [predictiveTags, setPredictiveTags] = useState();
  const [tagInput, setTagInput] = useState('');
  const [tags, setTags] = useState(initTags);
  const [focusedTag, setFocusedTag] = useState(null);
  const [tooltipDirection, setTooltipDirection] = useState('up');
  const [invalid, setInvalid] = useState(false);

  useEffect(() => {
    setTags(initTags);
  }, [initTags]);

  const onUpgrade = () => {
    onModalClose(tags);
    // open upgrade flow
  };

  const tagKeyEntered = (e) => {
    if ([',', 'Enter'].includes(e.key)) {
      if (e.key === ',') commaCleared.current = true;
      addTag();
    }
  };

  const addTag = () => {
    if (tagInput.length < MINIMUM_TAG_LENGTH) return;
    const newTag = {
      tag: reduceToTagFormat(tagInput),
      tagLabel: tagInput,
    };
    addTagToList(newTag);
  };

  const addTagToList = async (newTag) => {
    const isDuplicate = findObjectInArray(tags, 'tag', newTag.tag);
    if (isDuplicate) {
      setInvalid(true);
      return;
    }

    const existingTag = null; // await checkIfTagExists(newTag.tag);

    const tagToAdd = existingTag || newTag;

    setTags([...tags, tagToAdd]);
    setTagInput('');
    setFocusedTag(null);

    tagRef.current.focus();
  };

  const onClickOutside = (evt) => {
    evt.persist();
    if (focusedTag) setFocusedTag(null);
  };

  const deleteTag = (index) => {
    // const idx = tags.indexOf(tag);
    tags.splice(index, 1);
    setTags(tags);
    setFocusedTag(null);
  };

  const runPredictiveSearch = async (txt) => {
    if (commaCleared.current) {
      commaCleared.current = false;
      return;
    }
    if (invalid) setInvalid(false);
    setTagInput(txt);

    if (txt.length < 2) {
      setPredictiveTags(null);
      return;
    }

    // const tag = reduceToTagFormat(txt);
    // const results = await searchTags(txt);
    const results = [];
    setPredictiveTags(results);
  };

  const focusTag = (e, tag) => {
    console.log(e);
    console.log('TAGS_ROW_HEIGHT: ', TAGS_ROW_HEIGHT);
    // measure top row of tags, so tooltip down not show cut off at top
    const dir = e.nativeEvent.pageY < TAGS_ROW_HEIGHT ? 'down' : 'up';
    setTooltipDirection(dir);

    const focus = tag === focusedTag ? null : tag;
    setFocusedTag(focus);
  };

  useEffect(() => {
    if (isVisible) tagRef.current.focus();
    else {
      setTagInput('');
      setInvalid(false);
    }
  }, [isVisible]);

  return (
    <Modal
      isVisible={isVisible}
      animationIn="slideInUp"
      animationOut="slideOutDown"
      backdropColor="black"
      backdropOpacity={0.7}
      onBackdropClick={() => onModalClose(tags)}
      maxHeight={true}
      maxWidth={true}>
      <Wrapper id="tagsModal" onClick={onClickOutside}>
        <CloseBtn onClick={() => onModalClose(tags)}>
          <MdClose />
        </CloseBtn>
        <Container>
          <Content>
            <Header>
              <InputRow>
                <TextInput
                  ref={tagRef}
                  isInvalid={invalid}
                  value={tagInput}
                  onChange={(e) => runPredictiveSearch(e.target.value)}
                  onKeyDown={tagKeyEntered}
                  placeholder="Enter tag"
                  maxLength={100}
                />
                <AddBtn onClick={addTag}>
                  <AddBtnIcon>
                    <span></span>
                    <span></span>
                  </AddBtnIcon>
                </AddBtn>
              </InputRow>
            </Header>
            <PredictiveTagsRow
              tags={predictiveTags}
              restricted={tags}
              onTagClick={addTagToList}
            />
            <TagsScroll>
              <TagsWrapper>
                {tags.length > 0 ? (
                  tags.map(({ tag }, index) => (
                    <TagBlock
                      key={`tag-${index}`}
                      focused={focusedTag && focusedTag === tag}
                      disabled={focusedTag && focusedTag !== tag}>
                      <TagBtn
                        onClick={(evt) => focusTag(evt, tag)}
                        disabled={focusedTag && focusedTag !== tag}>
                        {`#${tag}`}
                      </TagBtn>

                      {focusedTag === tag ? (
                        <DeleteTooltipWrapper direction={tooltipDirection}>
                          <DeleteBtn onClick={() => deleteTag(index)}>
                            {tooltipDirection === 'down' ? (
                              <TooltipPoint direction={tooltipDirection} />
                            ) : null}
                            <TooltipBody>
                              <FiTrash2 />
                            </TooltipBody>
                            {tooltipDirection === 'down' ? null : (
                              <TooltipPoint direction={tooltipDirection} />
                            )}
                          </DeleteBtn>
                        </DeleteTooltipWrapper>
                      ) : null}
                    </TagBlock>
                  ))
                ) : (
                  <EmptyWrapper>
                    <p>{isPro ? EMPTY_MSG_PRO : EMPTY_MSG_FREE}</p>
                    {isPro ? null : (
                      <UpgradeBtn onClick={onUpgrade}>
                        Upgrade to PRO
                      </UpgradeBtn>
                    )}
                  </EmptyWrapper>
                )}
              </TagsWrapper>
            </TagsScroll>
          </Content>
        </Container>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  position: relative;
  margin-top: 40px;
  flex: 1;
  overflow: hidden;
  width: 100%;
  height: calc(100% - 40px);
  display: flex;
  flex-direction: column;
`;

const CloseBtn = styled.button`
  background: transparent;
  width: 100%;
  text-align: left;
  padding: 45px 10px 40px;
  margin-bottom: 0;

  svg {
    font-size: 36px;
    color: white;
  }
`;

const Container = styled.div`
  position: relative;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-self: flex-end;
  border-radius: 15px 15px 0 0;
  overflow: hidden;
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${(props) => props.theme.backgroundColors.primary};
`;

const Header = styled.div`
  background-color: white;
  border-bottom: 1px solid ${(props) => props.theme.colors.border};
`;

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 20px 10px;
`;

const TextInput = styled.input`
  flex: 1;
  background: transparent;
  color: ${(props) =>
    props.isInvalid
      ? props.theme.colors.secondary
      : props.theme.textColors.primary};
  font-size: 16px;
  font-weight: 500;
  background: none;
  border: 0;
  height: 40px;
  padding: 0;
  pointer-events: auto;
`;

const AddBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid ${(props) => props.theme.colors.border};
  border-radius: 40px;
  width: 40px;
  height: 40px;
  margin-left: 10px;
  pointer-events: all;
`;

const AddBtnIcon = styled.div`
  margin-top: 2px;

  span {
    display: block;
    width: 16px;
    height: 4px;
    border-radius: 2px;
    background-color: ${(props) => props.theme.textColors.secondary};

    &:last-child {
      transform: rotate(-90deg) translateX(4px);
    }
  }
`;

const TagsScroll = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
  pointer-events: all;
`;

const TagsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20px 10px;
`;

const TagBlock = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ focused }) =>
    focused &&
    `
    position: relative;
    z-index: 10;
  `}

  ${({ disabled }) =>
    disabled &&
    `
    position: relative;
    z-index: 1;
    opacity: 0.1;
  `}
`;

const TagBtn = styled.button`
  display: block;
  padding: 8px 10px;
  background-color: white;
  outline: none;
  border: 1px solid ${(props) => props.theme.colors.border};
  border-radius: 4px;
  margin: 4px;
  color: ${(props) => props.theme.textColors.primary};
  font-size: 14px;
  font-weight: 500;
  pointer-events: all;
`;

const DeleteTooltipWrapper = styled.div`
  position: absolute;
  top: -34px;
  z-index: 15;
  display: flex;
  flex-direction: row;
  justify-content: center;

  ${({ direction }) =>
    direction === 'down' &&
    `
		top: 46px;
	`}
`;

const DeleteBtn = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: transparent;
  border: 0;
  outline: 0;
  pointer-events: all;
`;

const TooltipPoint = styled.span`
  display: flex;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  background-color: ${(props) => props.theme.colors.secondary};
  transform: rotate(45deg);
  margin-top: -6px;

  ${({ direction }) =>
    direction === 'down' &&
    `
		margin-top: 0;
		margin-bottom: -6px;
	`}
`;

const TooltipBody = styled.span`
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.secondary};
  width: 34px;
  height: 30px;

  svg {
    color: white;
    font-size: 16px;
  }
`;

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  p {
    width: 320px;
    font-size: 13px;
    line-height: 1.5;
    color: ${(props) => props.theme.textColors.secondary};
    text-align: center;
  }
`;

const UpgradeBtn = styled.button`
  background: transparent;
  outline: 0;
  border-radius: 16px;
  padding: 6px 14px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin-top: 15px;
  font-size: 12px;
  font-weight: 500;
  color: ${(props) => props.theme.textColors.primary};
  pointer-events: all;
`;

export default TagsModal;
