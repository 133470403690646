import React from 'react';
import styled from 'styled-components';
import ActivityIndicator from './ActivityIndicator';
import Button from './Button';
import { FaExclamationTriangle } from 'react-icons/fa';

const LoaderWithText = ({ error, paddingTop = 0, onRetry, children }) => (
  <LoadingWrapper paddingTop={paddingTop}>
    {error ? <FaExclamationTriangle /> : null}
    <p>{children}</p>
    {error ? null : <ActivityIndicator color="secondary" />}
    {onRetry ? (
      <Button primary onClick={onRetry}>
        Retry
      </Button>
    ) : null}
  </LoadingWrapper>
);

const LoadingWrapper = styled.div`
  position: absolute;
  top: ${(props) => props.paddingTop};
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    font-size: 12px;
    color: ${(props) => props.theme.textColors.secondary};
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.2px;
    margin-bottom: 20px;
  }

  svg {
    color: ${(props) => props.theme.textColors.secondary};
    font-size: 24px;
  }
`;

export default LoaderWithText;
