import React from 'react';
import styled from 'styled-components';
import logo from '../../../assets/images/logo.svg';

const CreatorRestricted = ({ onUpgrade, logoURL }) => (
  <Container>
    <ContentWrapper>
      <LogoLink href={logoURL} disabled={logoURL ? false : true}>
        <Logo src={logo} />
      </LogoLink>
      <Content>
        <h3>Clip Limit Reached</h3>
        <Meter>
          <ProgressBar />
          <Orb>10</Orb>
        </Meter>
        <p>
          Delete a clip or upgrade your account to PRO for unlimited clip
          storage.
        </p>
      </Content>
      <Footer>
        <UpgradeBtn onClick={onUpgrade}>Upgrade my account</UpgradeBtn>
      </Footer>
    </ContentWrapper>
  </Container>
);

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex: 1;

  @media only screen and (max-width: 767px) {
    padding: 20px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 1;
`;

const Content = styled.div`
  max-width: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex: 2;
  padding: 0 20px;

  h3 {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 25px;
  }
  p {
    font-size: 16px;
    line-height: 1.4;
    margin-top: 0;
    color: ${(props) => props.theme.textColors.secondary};
  }

  @media only screen and (max-width: 768px) {
    h3 {
      font-size: 18px;
    }
    p {
      font-size: 15px;
    }
  }
`;

const LogoLink = styled.a`
  margin-bottom: 36px;
  justify-self: flex-start;
  cursor: pointer;

  @media only screen and (min-width: 768px) {
    margin-top: 30px;
  }

  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
  `}
`;

const Logo = styled.img`
  width: 120px;

  @media only screen and (min-width: 768px) {
    width: 150px;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media only screen and (min-width: 768px) {
    flex: 1;
  }
`;

const UpgradeBtn = styled.button`
  display: block;
  border-radius: 2px;
  background-color: ${(props) => props.theme.colors.primary};
  color: white;
  font-size: 17px;
  font-weight: bold;
  letter-spacing: 0.5px;
  height: 50px;
  width: 280px;

  @media only screen and (max-width: 768px) {
    width: 100%;
    margin: 15px 0 0;
    font-size: 15px;
  }
`;

const Meter = styled.div`
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 25px;
  padding: 0 20px;

  span {
    display: block;
    color: ${(props) => props.theme.colors.warning};
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 6px;
  }
`;

const ProgressBar = styled.div`
  position: relative;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.secondary};
  height: 14px;
  width: 100%;
`;

const Orb = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.secondary};
  color: white;
  font-size: 13px;
  font-weight: bold;
  transform: translateX(-50%) translateY(-50%);
  box-shadow: 0 0 0 6px
    ${(props) => `rgba(${props.theme.colors.secondaryRGB}, 0.3)`};
`;

export default CreatorRestricted;
