// @flow
import React, { createContext, useReducer } from 'react';

const INITIAL_STATE = {
  file: null,
  filename: null,
  url: null,
  startTime: null,
  endTime: null,
  duration: null, // same as "offset"
  filmstrip: null,
  trimmedVideo: null,
  editMode: false,
  sourceURL: null,
  videoHeight: null,
  videoWidth: null,
};

export const CreatorContext = createContext(INITIAL_STATE);

const Context = ({ children }) => {
  const reducer = (state, { type, data }) => {
    switch (type) {
      case 'SET_CREATOR': {
        return {
          ...state,
          ...data,
        };
      }
      case 'CLEAR': {
        return INITIAL_STATE;
      }
      default: {
        return state;
      }
    }
  };

  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <CreatorContext.Provider value={{ state, dispatch }}>
      {children}
    </CreatorContext.Provider>
  );
};

export default Context;
