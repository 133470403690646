import React, { useContext, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { EJSON } from 'bson';
import { createFFmpeg } from '@ffmpeg/ffmpeg';
import { CreatorContext } from '../contexts/CreatorContext';
import { SystemContext } from '../contexts/SystemContext';

import ActivityIndicator from '../components/common/ActivityIndicator';
import EmptyState from '../components/common/EmptyState';
import LoaderWithText from '../components/common/LoaderWithText';
import CreatorClipping from '../components/creator/CreatorClipping';
import CreatorFileSelect from '../components/creator/CreatorFileSelect';
import CreatorFinalize from '../components/creator/CreatorFinalize';
import CreatorRestricted from '../components/creator/CreatorRestricted';

import { WEBAPP_URL, FREE_CLIP_COUNT } from '../../constants';

const ffmpeg = createFFmpeg({
  // log: true,
  corePath: 'https://unpkg.com/@ffmpeg/core@0.11.0/dist/ffmpeg-core.js',
});

const ENV = window.location.hostname === 'localhost' ? 'dev' : 'prod';

const CreatorPage = () => {
  const isInitialMount = useRef(true);

  const {
    state: { url, trimmedVideo },
    dispatch: dispatchCreator,
  } = useContext(CreatorContext);
  const {
    state: { realmUser, inWebView, fromApp, isPro, deepLinkURL },
  } = useContext(SystemContext);

  const [error, setError] = useState();
  const [restricted, setRestricted] = useState(false);
  const [loadingUserInfo, setLoadingUserInfo] = useState(true);
  const [ffmpegReady, setFFMPEGReady] = useState(false);

  // only used for app -> creator -> app (after save)
  const [completedClipID, setCompletedClipID] = useState(false);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;

      // FTUE will have clipCount as "undefined" initially bypass error by forcing to 0
      let clipCount = realmUser.customData?.clipCount;
      clipCount = clipCount ? EJSON.deserialize(clipCount) : 0;

      // if free user and current clipCount is at limit, stop user from creating
      if (!isPro && clipCount >= FREE_CLIP_COUNT) {
        setRestricted(true);
      }

      setLoadingUserInfo(false);
    }
  }, [realmUser, isPro]);

  const onFFMPEGError = () => {
    setError('Error loading video');
  };

  const onClearError = () => {
    dispatchCreator({ type: 'CLEAR' });
    setError();
  };

  const onBackToFileSelect = () => {
    setFFMPEGReady(false);
  };

  const onUpgrade = () => {
    if (inWebView) {
      try {
        const data = {
          event: 'showUpgrade',
        };
        window.ReactNativeWebView.postMessage(JSON.stringify(data));
      } catch (err) {
        console.log('Error messaging WebView: ', err);
      }
    } else if (fromApp) {
      window.open('clipbox://upgrade', '_blank');
    } else {
      window.location = `${WEBAPP_URL[ENV]}/upgrade?ref=creator`;
    }
  };

  const onClipSaveStarted = () => {
    // for free accounts -- tell WebView to show ad during save
    if (inWebView) {
      try {
        const data = { event: 'saveStarted' };
        window.ReactNativeWebView.postMessage(JSON.stringify(data));
      } catch (err) {
        console.log('Error messaging WebView: ', err);
      }
    }
  };

  const onClipComplete = (id) => {
    if (inWebView) {
      try {
        const data = {
          event: 'clipCreated',
          value: id,
        };
        window.ReactNativeWebView.postMessage(JSON.stringify(data));
      } catch (err) {
        console.log('Error messaging WebView: ', err);
      }
    } else if (fromApp) {
      // will show prompt to user with button to navigate back to app
      setCompletedClipID(id);
    } else {
      window.location = `${WEBAPP_URL[ENV]}/?clip=${id}`;
    }
  };

  const navigateBackToApp = () => {
    // open app to preview clip, close current tab in safari
    window.open(
      `clipbox://preview?v=${completedClipID}&ref=webcreator`,
      '_blank',
    );
    window.close();
  };

  // communicate with webview on creator state change
  useEffect(() => {
    if (inWebView) {
      try {
        const data = {
          event: 'hideCloseBtn',
          value: trimmedVideo || url ? true : false,
        };
        window.ReactNativeWebView.postMessage(JSON.stringify(data));
      } catch (err) {
        console.log('Error messaging WebView: ', err);
      }
    }
  }, [trimmedVideo, url, inWebView]);

  return (
    <Container>
      {restricted ? (
        <CreatorRestricted
          onUpgrade={onUpgrade}
          logoURL={inWebView || fromApp ? null : WEBAPP_URL[ENV]}
        />
      ) : loadingUserInfo ? (
        <LoadingWrapper>
          <ActivityIndicator color="secondary" />
        </LoadingWrapper>
      ) : (
        <Content id="MainContent">
          {completedClipID ? (
            <EmptyState
              title="Your clip is ready!"
              subtitle="Tap the button below to preview and share your clip in the Clipbox app."
              btnLabel="View My Clip"
              onClick={navigateBackToApp}
            />
          ) : trimmedVideo ? (
            <CreatorFinalize
              ffmpeg={ffmpeg}
              isPro={isPro}
              onSaveStart={onClipSaveStarted}
              onComplete={onClipComplete}
            />
          ) : url ? (
            <CreatorClipping
              ffmpeg={ffmpeg}
              isPro={isPro}
              onFFMPEGError={onFFMPEGError}
              onFFMPEGReady={() => setFFMPEGReady(true)}
              onBack={onBackToFileSelect}
            />
          ) : (
            <CreatorFileSelect
              isPro={isPro}
              onUpgrade={onUpgrade}
              deepLinkURL={deepLinkURL}
            />
          )}

          {error ? (
            <LoaderWithText error onRetry={onClearError}>
              Error Loading Video
            </LoaderWithText>
          ) : url && !ffmpegReady ? (
            <LoaderWithText>Loading video</LoaderWithText>
          ) : null}
        </Content>
      )}
    </Container>
  );
};

const Container = styled.div`
  background-color: white;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const Content = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-height: 570px) {
    overflow-y: scroll;
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  flex: 1;
`;

export default CreatorPage;
