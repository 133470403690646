// @flow
import React, { useRef, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { fetchFile } from '@ffmpeg/ffmpeg';
import { MdTimer, MdOutlineArrowRightAlt } from 'react-icons/md';
import Modal from './Modal';
import { CreatorContext } from '../../contexts/CreatorContext';
import { SystemContext } from '../../contexts/SystemContext';
import { toTimeString } from '../../hooks/useTextHelper';
import { readFileAsBase64 } from '../../hooks/useVideoHelper';
import ProBanner from '../creator/ProBanner';

const ThumbModal = ({
  isVisible,
  activeThumbTime,
  onModalClose,
  onThumbSelect,
  onCustomThumb,
  isPro,
  ffmpeg,
}) => {
  const {
    state: { file, filename, startTime, endTime },
  } = useContext(CreatorContext);
  const {
    state: { inWebView, fromApp },
  } = useContext(SystemContext);

  const isInitialMount = useRef(true);

  const [thumbs, setThumbs] = useState([]);

  const selectCustomThumb = () => {
    if (isPro) {
      onCustomThumb();
    } else {
      // callback to open upgrade modal
      if (inWebView) {
        try {
          const data = {
            event: 'showUpgrade',
          };
          window.ReactNativeWebView.postMessage(JSON.stringify(data));
        } catch (err) {
          console.log('Error messaging WebView: ', err);
        }
      } else if (fromApp) {
        window.open('clipbox://upgrade', '_blank');
      }
    }
    onModalClose();
  };

  useEffect(() => {
    const onInit = async () => {
      // generate thumbnail options
      if (!ffmpeg.isLoaded()) await ffmpeg.load();

      const NUMBER_OF_IMAGES = 7;
      const OFFSET = (endTime - startTime) / NUMBER_OF_IMAGES;

      try {
        let arr = [];
        ffmpeg.FS('writeFile', filename, await fetchFile(file));

        for (let i = 0; i < NUMBER_OF_IMAGES; i++) {
          let time =
            Math.round((i * OFFSET + OFFSET / 2 + startTime) * 100) / 100;
          if (time > endTime) time = endTime;
          const startTimeInSecs = toTimeString(time);

          try {
            await ffmpeg.run(
              '-ss',
              startTimeInSecs,
              '-i',
              filename,
              '-t',
              '00:00:1.000',
              '-vf',
              `scale=150:-1`,
              `thumb${i}.png`,
            );
            const data = ffmpeg.FS('readFile', `thumb${i}.png`);

            const blob = new Blob([data.buffer], { type: 'image/png' });
            const dataURI = await readFileAsBase64(blob);
            ffmpeg.FS('unlink', `thumb${i}.png`);

            const thumbObj = {
              time: startTimeInSecs,
              image: dataURI,
            };
            arr.push(thumbObj);
          } catch (err) {
            console.log(
              'Error processing thumbnail',
              startTimeInSecs,
              err.mesage,
            );
          }
        }

        setThumbs(arr);

        // set initial thumb in CreatorFinalize
        onThumbSelect(arr[0]);
      } catch (err) {
        console.log('Error making thumbnails: ', err);
        onThumbSelect(null);
      }
    };

    if (isInitialMount.current) {
      isInitialMount.current = false;
      onInit();
    }
  }, [ffmpeg, file, filename, startTime, endTime, onThumbSelect]);

  return (
    <Modal
      isVisible={isVisible}
      alignContent="right"
      animationIn="slideInRight"
      animationOut="slideOutRight"
      backdropColor="white"
      backdropOpacity={0.6}
      onBackdropClick={onModalClose}
      maxHeight={true}>
      <Container>
        <Content>
          <ThumbScroll>
            <ThumbScrollContent>
              <CustomThumbBtn onClick={selectCustomThumb}>
                <MdTimer />
                <span>Select Time</span>
                {!isPro ? <ProBanner /> : null}
              </CustomThumbBtn>

              {thumbs.map((thumb, index) => (
                <ThumbBtn
                  key={`${thumb.image}-${index}`}
                  onClick={() => onThumbSelect(thumb, false)}
                  active={thumb.time === activeThumbTime}>
                  <img src={thumb.image} alt="" />
                  {thumb.time === activeThumbTime ? <span></span> : null}
                </ThumbBtn>
              ))}
            </ThumbScrollContent>
          </ThumbScroll>
          <CloseBtn onClick={onModalClose}>
            <MdOutlineArrowRightAlt />
          </CloseBtn>
        </Content>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  position: relative;
  height: 100%;
  background-color: white;
  border-radius: 20px 0 0 20px;
  box-shadow: 0 0 10px
    rgba(${(props) => props.theme.textColors.primaryRGB}, 0.2);
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 140px;
  height: 100%;
`;

const ThumbScroll = styled.div`
  flex: 1;
  padding: 20px;
  overflow-y: scroll;
  white-space: nowrap;
  pointer-events: all;
`;

const ThumbScrollContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CustomThumbBtn = styled.button`
  position: relative;
  border-radius: 4px;
  border: 2px solid ${(props) => props.theme.colors.border};
  background-color: ${(props) => props.theme.backgroundColors.primary};
  margin-bottom: 10px;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 12px 0;
  width: 100%;

  svg {
    font-size: 24px;
    color: ${(props) => props.theme.textColors.secondary};
    margin-bottom: 6px;
  }

  span {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: -0.2px;
    color: ${(props) => props.theme.textColors.secondary};
  }
`;

const ThumbBtn = styled.button`
  position: relative;
  background-color: ${(props) => props.theme.backgroundColors.lightAlt};
  border-radius: 4px;
  width: ${(props) => (props.active ? '100px' : '92px')};
  height: ${(props) => (props.active ? '75px' : '69px')};
  overflow: hidden;
  margin: 10px 0;
  padding: 0;
  align-self: center;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    border: 6px solid ${(props) => props.theme.colors.primary};
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  }
`;

const CloseBtn = styled.button`
  background-color: white;
  padding: 10px;
  border: none;
  outline: none;
  border-top: 1px solid ${(props) => props.theme.colors.border};
  margin-top: auto;

  svg {
    color: ${(props) => props.theme.textColors.secondary};
    font-size: 32px;
  }
`;

export default ThumbModal;
