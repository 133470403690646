// @flow
import React, { forwardRef, useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { FiVolumeX, FiVolume2 } from 'react-icons/fi';
import { FaStopCircle, FaStepBackward, FaStepForward } from 'react-icons/fa';
import PlaybackIndicator from './PlaybackIndicator';
import ActivityIndicator from '../common/ActivityIndicator';
import { formatTimestamp } from '../../hooks/useTextHelper';

// type Props = {
//   src: String,
//   startTime: Number,
//   playbackBounds: Array,
//   disabled?: Boolean,
//   onLoad?: Function,
//   onCaptureTime?: Function,
//   trimMode: Boolan,
// };

const formatTime = time => {
  const t = time || 0;
  return formatTimestamp(Math.floor(t), true);
};

const CreatorVideo = forwardRef(
  (
    {
      src,
      showMute,
      startTime,
      playbackBounds,
      disabled,
      onLoad,
      onError,
      onCaptureTime,
      trimMode,
    },
    ref,
  ) => {
    const videoTime = useRef(0);
    const [currentTime, setCurrentTime] = useState(formatTime(startTime));
    const [loading, setLoader] = useState(true);
    const [muted, setMuted] = useState(false);
    const [isPaused, setPaused] = useState(true);

    const progressInterval = useRef(null);

    const onPlaybackPaused = () => {
      setPaused(true);
      if (progressInterval.current) {
        clearInterval(progressInterval.current);
        progressInterval.current = null;
      }
    };

    useEffect(() => {
      // when component is unmounted, garbage collect interval
      return () => {
        if (progressInterval.current) clearInterval(progressInterval.current);
      };
    }, []);

    const handleProgress = () => {
      videoTime.current = ref.current.currentTime;

      const time = formatTime(ref.current.currentTime);
      setCurrentTime(time);

      // when video is currently playing and within 2 seconds of end boundary:
      // - start interval every 0.01s to stop it on selected time
      const inFinalTwoSeconds = playbackBounds
        ? playbackBounds[1] - ref.current.currentTime < 2
        : null;
      if (
        !progressInterval.current &&
        !ref.current.paused &&
        inFinalTwoSeconds
      ) {
        const checkEndBoundTime = () => {
          if (ref.current.currentTime >= playbackBounds[1]) {
            ref.current.pause();

            // clear interval and reference
            clearInterval(progressInterval.current);
            progressInterval.current = null;
          }
        };

        // run interval every 0.01 sec until boundary is reached
        progressInterval.current = setInterval(checkEndBoundTime, 10);
      }
    };

    const captureTime = () => {
      ref.current.pause();
      onCaptureTime(videoTime.current);
    };

    const togglePlayback = () => {
      if (isPaused && playbackBounds) {
        const remainder = playbackBounds[1] - videoTime.current;
        // remainder this close means video is at end, start from beginning
        if (remainder < 0.1) replay('start');
      }

      if (isPaused) ref.current.play();
      else ref.current.pause();
    };

    const toggleMute = () => {
      setMuted(!muted);
      ref.current.muted = !muted;
    };

    const replay = direction => {
      let time = playbackBounds ? playbackBounds[0] : startTime;
      if (direction === 'end') time = playbackBounds[1] - 1;
      ref.current.currentTime = time;
      ref.current.play();
    };

    const onVideoLoad = e => {
      const el = e.target;

      const meta = {
        duration: el.duration,
        videoWidth: el.videoWidth,
        videoHeight: el.videoHeight,
      };

      if (startTime) ref.current.currentTime = startTime.toString();

      setLoader(false);

      if (onLoad) onLoad(meta);
    };

    const onVideoError = e => {
      console.log('onVideoError: ', e);
      onError();
    };

    return (
      <Wrapper id="CreatorVideo">
        {loading ? (
          <LoadingWrapper>
            <ActivityIndicator color="white" />
          </LoadingWrapper>
        ) : !disabled ? (
          <Timestamp>
            <p>{currentTime}</p>
          </Timestamp>
        ) : null}
        <video
          ref={ref}
          muted={muted}
          onTimeUpdate={handleProgress}
          onLoadedMetadata={onVideoLoad}
          onError={onVideoError}
          onPlay={() => setPaused(false)}
          onPause={onPlaybackPaused}
          playsInline={true}
          crossOrigin="anonymous"
          height="100%">
          <source src={src} type="video/mp4" />
        </video>

        {disabled ? null : (
          <Overlays>
            {showMute ? (
              <MuteBtn onClick={toggleMute}>
                {muted ? <FiVolumeX /> : <FiVolume2 />}
              </MuteBtn>
            ) : null}
            <PlaybackIndicator paused={isPaused} />
            {isPaused ? (
              <PlaybackControlSideBtn onClick={() => replay('start')}>
                <FaStepBackward />
              </PlaybackControlSideBtn>
            ) : null}
            {isPaused && trimMode === 'trim' ? (
              <PlaybackControlSideBtn
                onClick={() => replay('end')}
                isEnd="true">
                <FaStepForward />
              </PlaybackControlSideBtn>
            ) : null}
            {onCaptureTime ? (
              <CaptureBtn
                onClick={onCaptureTime ? captureTime : null}
                isPaused={isPaused}>
                <FaStopCircle />
              </CaptureBtn>
            ) : null}
          </Overlays>
        )}
        {disabled || loading ? null : (
          <HitState onClickCapture={togglePlayback} />
        )}
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;

  display: flex;
  justify-content: center;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  video {
    background: ${props => props.theme.backgroundColors.primary};
  }
`;

const HitState = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  background: transparent;
  outline: none;
  border: 0;
  cursor: pointer;

  &:active {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Overlays = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 6;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  button {
    pointer-events: all;
  }
`;

const MuteBtn = styled.button`
  display: flex;
  background: transparent;
  position: absolute;
  bottom: 4px;
  right: 10px;

  svg {
    color: 'white';
    font-size: 28px;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.2);
  }
`;

const Timestamp = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 5;
  background-color: rgba(255, 255, 255, 0.4);
  padding: 6px 8px;
  border-radius: 6px;

  p {
    color: black;
    font-size: 11px;
    font-weight: 600;
    text-align: center;
    margin: 0;
  }
`;

const CaptureBtn = styled.button`
  background: transparent;
  border: 0;
  outline: none;
  position: absolute;
  bottom: 10px;
  right: 10px;

  svg {
    font-size: 36px;
    color: white;
    opacity: 0.8;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  }

  &:hover svg {
    opacity: 1;
  }

  transition: transform 0.5s ease-in;
  transform: ${props =>
    props.isPaused ? 'translateX(100px)' : 'translateX(0)'};
`;

const PlaybackControlSideBtn = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 3;
  width: 150px;
  margin: 0;
  padding: 0 20px;
  justify-content: center;
  background: linear-gradient(
    ${props => (props.isEnd ? '270deg' : '90deg')},
    rgba(0, 0, 0, 0.54) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  text-align: ${props => (props.isEnd ? 'right' : 'left')};

  svg {
    font-size: 28px;
    color: white;
    opacity: 0.8;
  }

  &:hover svg {
    opacity: 1;
  }

  ${({ isEnd }) =>
    isEnd &&
    `
		left: auto;
		right: 0;
		align-items: flex-end;
	`}
`;

export default CreatorVideo;
