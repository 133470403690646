// @flow
import React from 'react';
import styled from 'styled-components';
import { FaMinus, FaPlus } from 'react-icons/fa';

const CreatorTimeControls = ({
  mode,
  onDecrease,
  onIncrease,
  time,
  openTimeSelector,
  disabled,
  timeSelectorDisabled,
}) => (
  <Controls>
    <DecimalBtn onClick={() => onDecrease(mode)} disabled={disabled.min}>
      <FaMinus />
    </DecimalBtn>

    <TimeBlockWrapper>
      <TimeBlockBtn
        onClick={() => openTimeSelector(mode)}
        mode={mode}
        disabled={timeSelectorDisabled}>
        {time}
      </TimeBlockBtn>
    </TimeBlockWrapper>

    <DecimalBtn onClick={() => onIncrease(mode)} disabled={disabled.max}>
      <FaPlus />
    </DecimalBtn>
  </Controls>
);

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding: 12px 40px;

  @media only screen and (min-width: 768px) {
    margin-bottom: 10px;
  }

  @media only screen and (max-height: 570px) {
    padding: 8px 0;
  }
`;

const DecimalBtn = styled.button`
  background-color: white;
  height: 42px;
  width: 42px;
  border-radius: 21px;
  border: 2px solid ${(props) => props.theme.colors.border};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    font-size: 20px;
    color: ${(props) => props.theme.textColors.secondary};
  }

  ${({ disabled }) =>
    disabled &&
    `
		pointer-events: none;
		cursor: default;

		svg {
			opacity: 0.4;
		}
	`}
`;

const TimeBlockWrapper = styled.div`
  margin: 0 20px;
`;

const TimeBlockBtn = styled.button`
  background-color: ${(props) => props.theme.backgroundColors.lightAlt};
  padding: 20px 40px;
  border: 2px solid ${(props) => props.theme.colors.border};
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left-color: ${(props) =>
    props.mode === 0 ? props.theme.colors.primary : props.theme.colors.border};
  border-right-color: ${(props) =>
    props.mode === 1 ? props.theme.colors.primary : props.theme.colors.border};
  cursor: pointer;

  color: ${(props) => props.theme.colors.primary};
  font-size: 20px;
  font-weight: 700;
  text-align: center;

  ${({ disabled }) =>
    disabled &&
    `
		pointer-events: none;
		cursor: default;
	`}
`;

export default CreatorTimeControls;
