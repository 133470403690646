import { CREATOR_MIN_DURATION } from '../../constants';

export const getMinTime = (currentTime, maxDuration) => {
  // start handle cannot be less than max duration or less than 0
  const MIN = currentTime[1] - maxDuration;
  const startHandle = MIN < 0 ? 0 : Math.ceil(MIN);

  // end handle cannot be less than min duration or more than max duration (above)
  const LOW = currentTime[0] + CREATOR_MIN_DURATION;
  const endHandle = MIN <= currentTime[0] ? Math.ceil(LOW) : Math.ceil(MIN);
  return [startHandle, endHandle];
};

export const getMaxTime = (
  multiple,
  duration,
  bounds,
  currentTime,
  maxDuration,
) => {
  const UPPER_LIMIT = bounds ? bounds[1] : duration;
  const startHandle = multiple
    ? currentTime[1] - CREATOR_MIN_DURATION
    : UPPER_LIMIT;

  const MAX = currentTime[0] + maxDuration;
  const endHandle = MAX > UPPER_LIMIT ? UPPER_LIMIT : MAX;
  return [startHandle, endHandle];
};

export const onDecreaseTime = (btnIndex, bounds, currentTime) => {
  let time = currentTime[btnIndex] - 0.1;

  if (btnIndex === 0) {
    // start time conditionals
    const minLimit = bounds ? bounds[0] : 0;
    if (time < minLimit) time = minLimit;
  } else {
    // end time conditionals
    const MIN = currentTime[0] + CREATOR_MIN_DURATION;
    if (time < MIN) time = MIN;
  }

  let newTimes = [...currentTime];
  newTimes[btnIndex] = time;

  return newTimes;
};

export const onIncreaseTime = (
  multiple,
  btnIndex,
  duration,
  bounds,
  currentTime,
  maxDuration,
  noLimit,
) => {
  let time = currentTime[btnIndex] + 0.1;

  if (btnIndex === 0) {
    // start time conditionals
    const MAX = multiple
      ? currentTime[1] - CREATOR_MIN_DURATION
      : noLimit
      ? duration
      : bounds
      ? bounds[1] - CREATOR_MIN_DURATION
      : duration - CREATOR_MIN_DURATION;
    if (time > MAX) time = MAX;
  } else {
    // end time conditionals
    const MAX = duration > 30 ? currentTime[0] + maxDuration : duration;
    if (time > MAX) time = MAX;
  }

  let newTimes = [...currentTime];
  newTimes[btnIndex] = time;

  return newTimes;
};

export const checkDisabledBtns = (
  multiple,
  time,
  bounds,
  duration,
  maxDuration,
  noLimit,
) => {
  // not longer than MAX_DURATION
  const START_MAX = multiple
    ? time[1]
    : bounds
    ? bounds[0] + duration
    : duration;
  // not shorter than MIN_DURATION, not less than 0
  const START_MIN = multiple ? time[1] - maxDuration : bounds ? bounds[0] : 0;

  const upperLimit = noLimit ? START_MAX : START_MAX - CREATOR_MIN_DURATION;
  let disabledValues = [
    {
      max: time[0] >= Math.floor(upperLimit * 10) / 10,
      min: time[0] <= START_MIN || time[0] <= 0,
    },
  ];

  // if multiple, add second batch of arguments for end point's time controls
  if (multiple) {
    // no longer than max duration, no longer than length of original video
    const END_MAX = duration > 30 ? time[0] + maxDuration : duration;
    disabledValues[1] = {
      max: time[1] >= END_MAX || time[1] >= bounds[1],
      min: time[1] - time[0] <= CREATOR_MIN_DURATION, // no smaller than minimum duration
    };
  }

  return disabledValues;
};
