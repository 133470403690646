import React, { useContext, useState, useRef } from 'react';
import styled from 'styled-components';
import { lighten } from 'polished';
import {
  MdChevronLeft,
  MdCheckBox,
  MdCheckBoxOutlineBlank,
} from 'react-icons/md';
import { ObjectID } from 'bson';
import { Buffer } from 'buffer';
import CreatorHeader from './CreatorHeader';
import ProThumbModal from '../modals/ProThumbModal';
import TagsModal from '../modals/TagsModal';
import ThumbModal from '../modals/ThumbModal';
import { CreatorContext } from '../../contexts/CreatorContext';
import { SystemContext } from '../../contexts/SystemContext';
import { readFileAsBase64 } from '../../hooks/useVideoHelper';
import ActivityIndicator from '../common/ActivityIndicator';
import useAmplitude from '../../hooks/useAmplitude';
import { getSourceName } from '../../hooks/useTextHelper';

const MAX_TITLE = 100;

const CreatorFinalize = ({ ffmpeg, isPro, onSaveStart, onComplete }) => {
  const {
    state: {
      filmstrip,
      trimmedVideo,
      filename,
      startTime,
      endTime,
      duration,
      sourceURL,
      videoWidth,
      videoHeight,
    },
    dispatch: dispatchCreator,
  } = useContext(CreatorContext);
  const {
    state: { realmUser },
  } = useContext(SystemContext);

  const inputRef = useRef();
  const { trackEvent } = useAmplitude();

  const [terms, setTerms] = useState(true);
  const [title, setTitle] = useState('');
  const [tags, setTags] = useState([]);
  const [showTagsModal, setShowTagsModal] = useState(false);
  const [showThumbModal, setShowThumbModal] = useState(false);
  const [playlist, setPlaylist] = useState();
  const [showPlaylistModal, setShowPlaylistModal] = useState(false);
  const [thumb, setThumb] = useState();
  const [customThumb, setCustomThumb] = useState(false);
  const [showProThumb, setShowProThumb] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState();

  const onBackClick = () => {
    // clear trimmedVideo, resets CreatorPage back to CreatorClipping
    dispatchCreator({
      type: 'SET_CREATOR',
      data: {
        trimmedVideo: null,
      },
    });
  };

  const openTags = () => {
    inputRef.current.blur();
    setShowTagsModal(true);
  };

  const openPlaylistModal = () => {
    inputRef.current.blur();

    if (isPro) setShowPlaylistModal(true);
    else {
      // open app to upgrade screen
    }
  };

  const closeTags = (newTags) => {
    setShowTagsModal(false);
    setTags(newTags);
  };

  const openThumb = () => {
    inputRef.current.blur();
    setShowThumbModal(true);
  };

  const onThumbSelect = (newThumb, isCustom) => {
    setCustomThumb(isCustom);
    setShowThumbModal(false);
    setShowProThumb(false);

    // set selected thumbnail as active
    setThumb(newThumb || filmstrip[0]);
  };

  const uploadFile = async (clipId, file, type) => {
    const { url: uploadUrl } = await realmUser.functions.getUploadUrl(
      type,
      clipId,
    );
    let re =
      type === 'mp4' ? /^data:video\/\w+;base64,/ : /^data:image\/\w+;base64,/;

    const buffer = Buffer.from(file.replace(re, ''), 'base64');

    const options = {
      method: 'PUT',
      body: buffer,
      headers: {
        'Content-Type': `video/${type}`,
        'x-amz-acl': 'public-read',
      },
    };

    const upload = await fetch(uploadUrl, options);
    console.log(upload);

    // this may need to resolve a promise
    return true;
  };

  const generatePreview = async (clipId, time) => {
    // ffmpeg passed in from <CreatorPage> check if already loaded
    if (!ffmpeg.isLoaded()) await ffmpeg.load();

    await ffmpeg.run(
      '-ss',
      time,
      '-i',
      filename,
      '-t',
      '00:00:1.000',
      '-vf',
      `scale='if(gt(a,1),1280,-1):if(gt(a,1),-1,1280)'`,
      `${clipId}.png`,
    );

    const data = ffmpeg.FS('readFile', `${clipId}.png`);
    const blob = new Blob([data.buffer], { type: 'image/png' });
    const dataURI = await readFileAsBase64(blob);
    ffmpeg.FS('unlink', `${clipId}.png`);

    return dataURI;
  };

  const onSave = async () => {
    // tell CreatorPage to show ad over WebView in app
    if (!isPro) onSaveStart();

    setSaving(true);
    setError();

    try {
      const clipId = new ObjectID().toString();

      // Construct Clip Object
      const clipObj = {
        _id: clipId,
        userId: realmUser.id,
        sourceUrl: sourceURL,
        title,
        tags,
        created: new Date(),
        startTime: Number(startTime),
        endTime: Number(endTime),
        thumbTime: thumb.time,
        duration,
        width: videoWidth,
        height: videoHeight,
        clip: `https://media.clipbox.app/${realmUser.id}/${clipId}.mp4`,
        preview: `https://media.clipbox.app/${realmUser.id}/${clipId}.png`,
        thumb: `https://media.clipbox.app/${realmUser.id}/${clipId}-thumb.png`,
      };

      const preview = await generatePreview(clipId, thumb.time);

      // Upload Files
      // Maybe run these simultaneously with like Promise.all ?
      await uploadFile(clipId, preview, 'png');
      await uploadFile(`${clipId}-thumb`, thumb.image, 'png');
      await uploadFile(clipId, trimmedVideo, 'mp4');

      await realmUser.functions.saveClip(clipObj);

      // log clip created in Amplitude
      let metricProps = {
        'Clip ID': clipId,
        'Clip Duration': Math.round(duration * 10) / 10,
        'Clip Title': title,
        'Clip Tags': Array.from(tags, (t) => t.tagLabel),
        'Clip Shape':
          videoHeight === videoWidth
            ? 'Square'
            : videoHeight > videoWidth
            ? 'Vertical'
            : 'Landscape',
        'Custom Thumb': customThumb ? 'Yes' : 'No',
      };
      if (sourceURL) metricProps.Source = getSourceName(sourceURL);
      if (playlist) metricProps.Playlist = playlist;
      trackEvent('Clip Created', metricProps);

      // pass back clip id and tell creator app to close & show clip preview
      onComplete(clipId);
    } catch (err) {
      console.log('Error saving clip: ', err);
      setError('Error saving clip. Please try again');
      setSaving(false);
    }

    setSaving(false);
  };

  const TITLE_COUNT = MAX_TITLE - title.length;

  return (
    <Wrapper id="CreatorFinalize">
      <CreatorHeader
        btnLeftIcon={<MdChevronLeft />}
        onBack={onBackClick}
        title="Finalize & Save"
      />
      <Body>
        <Content disabled={saving}>
          <MetaWrapper>
            <TitleWrapper>
              <TitleInput
                ref={inputRef}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Enter a title for your clip ..."
                maxLength={MAX_TITLE}
                disabled={saving}
              />
              <TitleCount warn={TITLE_COUNT < 10} isVisible={TITLE_COUNT < 21}>
                {TITLE_COUNT}
              </TitleCount>
            </TitleWrapper>

            {thumb ? (
              <ThumbBtn onClick={openThumb} disabled={saving}>
                <Thumbnail src={thumb?.image} />
                <EditThumbTxt>Edit Thumb</EditThumbTxt>
              </ThumbBtn>
            ) : (
              <ThumbBtn disabled>
                <ActivityIndicator size={20} color="#999FAF" />
              </ThumbBtn>
            )}
          </MetaWrapper>
          <TagsArea>
            {tags.length > 0 ? (
              <TagsContainer>
                <TagsWrapper>
                  {tags.map(({ tag }, index) => (
                    <Tag key={`${tag}-${index}`}>{`#${tag}`}</Tag>
                  ))}
                </TagsWrapper>
                <EditTagsBtn onClick={openTags} disabled={saving}>
                  #Edit Tags
                </EditTagsBtn>
              </TagsContainer>
            ) : (
              <AddTagsBtn onClick={openTags} disabled={saving}>
                #Add Tags
              </AddTagsBtn>
            )}
          </TagsArea>
          {/* <PlaylistBtn onClick={openPlaylistModal}>
            <label active={playlist}>
              {playlist ? playlist.title : 'Add to Playlist'}
            </label>
            {isPro ? null : <ProBadge>PRO</ProBadge>}
            <MdChevronRight />
          </PlaylistBtn> */}
        </Content>
        <Footer id="Footer">
          {error ? <ErrorMsg>{error}</ErrorMsg> : null}
          <CheckBtn
            onClick={() => setTerms(!terms)}
            disabled={saving}
            isChecked={terms}>
            {terms ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}
            <p>
              I agree to the{' '}
              <a
                href="https://clipbox.app/terms"
                rel="noopener noreferrer"
                target="_blank">
                Terms of Service
              </a>
            </p>
          </CheckBtn>
          <SaveBtn disabled={!thumb || saving} onClick={onSave}>
            {saving ? (
              <ActivityIndicator size={18} color="white" />
            ) : (
              'Save Clip'
            )}
          </SaveBtn>
        </Footer>
      </Body>
      <TagsModal
        isVisible={showTagsModal}
        onModalClose={closeTags}
        initTags={tags}
        isPro={isPro}
      />
      <ThumbModal
        isVisible={showThumbModal}
        onModalClose={() => setShowThumbModal(false)}
        onThumbSelect={onThumbSelect}
        onCustomThumb={() => setShowProThumb(true)}
        activeThumbTime={thumb?.time}
        isPro={isPro}
        ffmpeg={ffmpeg}
      />
      {isPro ? (
        <ProThumbModal
          isVisible={showProThumb}
          onModalClose={() => setShowProThumb(false)}
          onThumbSelect={onThumbSelect}
          startTime={startTime}
          bounds={[startTime, endTime]}
          ffmpeg={ffmpeg}
        />
      ) : null}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  width: 100%;
  background-color: ${(props) => props.theme.backgroundColors.primary};
`;

const Content = styled.div`
  flex: 1;
  width: 100%;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-width: 800px;
  background-color: white;

  @media only screen and (max-width: 768px) {
    max-width: 100%;
  }
`;

const MetaWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.border};
  padding: 20px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const TitleInput = styled.textarea`
  background-color: transparent;
  border: none;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  flex: 1;
  resize: none;
  padding: 0;
  border-radius: 0;
  width: 100%;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
`;

const TitleCount = styled.span`
  color: ${(props) => props.theme.textColors.secondary};
  font-weight: 500;
  margin-top: 10px;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};

  ${({ warn, theme }) =>
    warn &&
    `
		color: ${theme.colors.secondary};
		font-weight: bold;
		opacity: 1;
	`}
`;

const ThumbBtn = styled.button`
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.backgroundColors.lightAlt};
  width: 120px;
  height: 90px;
  border-radius: 4px;
  overflow: hidden;
  margin-left: 20px;
  outline: none;
  padding: 0;

  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
    cursor: default;
  `}
`;

const Thumbnail = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const EditThumbTxt = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px;
  color: white;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: -0.1px;
  text-align: center;
  text-transform: uppercase;
`;

const TagsArea = styled.div`
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.border};
  margin-top: 2px;
  padding: 20px;
`;

const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TagsWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Tag = styled.span`
  color: ${(props) => props.theme.textColors.secondary};
  font-size: 13px;
  font-weight: 500;
  margin: 3px;
`;

const EditTagsBtn = styled.button`
  background-color: white;
  border: 2px solid ${(props) => props.theme.colors.border};
  border-radius: 4px;
  padding: 10px 12px;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: -0.2px;
  color: ${(props) => props.theme.textColors.secondary};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
`;

const AddTagsBtn = styled.button`
  background-color: white;
  border: 2px solid ${(props) => props.theme.colors.border};
  border-radius: 4px;
  padding: 10px 12px;
  margin-right: auto;
  color: ${(props) => props.theme.textColors.primary};
  font-size: 14px;
  font-weight: 500;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
`;

const Footer = styled.div`
  margin-top: auto;
  padding: 0 20px 20px;
  width: 100%;

  @media only screen and (min-width: 992px) {
    padding-bottom: 50px;
  }
`;

const CheckBtn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    font-size: 30px;
    color: ${(props) =>
      props.isChecked
        ? props.theme.colors.primary
        : props.theme.colors.grayLight};

    &:hover {
      color: ${(props) =>
        props.isChecked
          ? lighten(0.2, props.theme.colors.primary)
          : lighten(0.2, props.theme.colors.grayLight)};
    }
  }

  p {
    font-size: 13px;
    color: ${(props) => props.theme.textColors.secondary};
    text-align: left;
    margin: 0 0 0 8px;

    a {
      font-weight: bold;
      text-decoration: none;
      color: ${(props) => props.theme.textColors.secondary};

      &:hover {
        color: ${(props) => props.theme.colors.primary};
      }
    }
  }

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.5;
    pointer-events: none;
  `}

  @media only screen and (min-width: 992px) {
    margin-bottom: 30px;

    p {
      font-size: 14px;
    }
  }
`;

const SaveBtn = styled.button`
  display: block;
  border-radius: 2px;
  background-color: ${(props) => props.theme.colors.primary};
  color: white;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.5px;
  margin-top: 15px;
  height: 50px;
  width: 100%;

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.4;
    pointer-events: none;
    cursor: default;
  `}

  &:hover,
  &:active {
    background-color: ${(props) => lighten(0.1, props.theme.colors.primary)};
  }
`;

const PlaylistBtn = styled.button`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.border};
  background: transparent;
  padding: 20px;
  width: 100%;

  label {
    flex: 1;
    font-size: 15px;
    font-weight: 500;
    text-align: left;
    letter-spacing: normal;
    text-transform: none;
    color: ${(props) =>
      props.active
        ? props.theme.textColors.primary
        : props.theme.textColors.secondary};
    margin-bottom: 0;
    user-select: none;
  }

  svg {
    margin-right: -5px;
    margin-left: 10px;
    font-size: 28px;
    color: ${(props) => props.theme.textColors.secondary};
  }
`;

const ProBadge = styled.span`
  display: block;
  background-color: ${(props) => props.theme.colors.secondary};
  padding: 4px 8px;
  border-radius: 14px;
  margin-left: 15px;

  color: white;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 0.2px;
  font-weight: bold;
`;

const ErrorMsg = styled.p`
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.2px;
  color: ${(props) => props.theme.colors.warning};
  margin-top: 10px;
  text-align: center;
`;

export default CreatorFinalize;
