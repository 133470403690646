import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';

const slideInUp = keyframes`
	from { 
		bottom: 0;
		transform: translateY(100%);
	}
	to { 
		bottom: 0;
		transform: translateY(0);
	}
`;

const slideOutDown = keyframes`
	from {
		bottom: 0;
		transform: translateY(0);
	}
	to {
		bottom: 0;
		transform: translateY(100%);
	}
`;

const slideInRight = keyframes`
	from { 
		right: 0;
		transform: translateX(100%);
	}
	to { 
		right: 0;
		transform: translateX(0);
	}
`;

const slideOutRight = keyframes`
	from { 
		right: 0;
		transform: translateX(0);
	}
	to {
		right: 0;
		transform: translateX(100%);
	}
`;

const fadeIn = keyframes`
	from { opacity: 0; }
	to { opacity: 1; }
`;

const fadeOut = keyframes`
	from { opacity: 1; }
	to { opacity: 0; }
`;

const ANIMATIONS = {
  slideInRight,
  slideOutRight,
  slideInUp,
  slideOutDown,
  fadeIn,
  fadeOut,
};

const Modal = ({
  isVisible,
  alignContent = 'left',
  animationIn = 'fadeIn',
  animationOut = 'fadeOut',
  animationTime = 500,
  backdropColor = 'black',
  backdropOpacity = 0.8,
  onBackdropClick,
  onModalHide,
  maxHeight,
  maxWidth,
  children,
}) => {
  const isInitialMount = useRef(true);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (isInitialMount.current) {
      // this stops animationOut from runing on init
      isInitialMount.current = false;
      return;
    }

    setReady(true);
  }, [isVisible]);

  return (
    <Wrapper>
      <Backdrop
        isVisible={isVisible}
        backdropOpacity={backdropOpacity}
        onClick={onBackdropClick}
        style={{ backgroundColor: backdropColor }}
      />
      <Content
        align={alignContent}
        animation={
          !ready
            ? 'none'
            : isVisible
            ? ANIMATIONS[animationIn]
            : ANIMATIONS[animationOut]
        }
        animationTime={animationTime}
        initAnimation={animationIn}
        maxHeight={maxHeight}
        maxWidth={maxWidth}>
        {children}
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 500;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

const Backdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: opacity 0.25s ease-in;
  opacity: ${(props) => (props.isVisible ? props.backdropOpacity : 0)};
  pointer-events: ${(props) => (props.isVisible ? 'all' : 'none')};
`;

const Content = styled.div`
  position: absolute;
  z-index: 2;
  height: ${(props) => (props.maxHeight ? '100%' : 'initial')};
  width: ${(props) => (props.maxWidth ? '100%' : 'initial')};
  display: flex;
  pointer-events: all;
  justify-content: ${(props) =>
    props.align === 'right'
      ? 'flex-end'
      : props.align === 'center'
      ? 'center'
      : 'flex-start'};
  animation-name: ${(props) => props.animation};
  animation-duration: ${(props) => `${props.animationTime}ms`};
  animation-fill-mode: forwards;
  ${({ initAnimation }) =>
    initAnimation === 'slideInUp' &&
    `
    bottom: 0;
    transform: translateY(100%);
  `}
  ${({ initAnimation }) =>
    initAnimation === 'slideInRight' &&
    `
    right: 0;
    transform: translateX(100%);
  `}

  ${({ initAnimation }) =>
    initAnimation === 'fadeIn' &&
    `
    opacity: 0;
  `}
`;

export default Modal;
