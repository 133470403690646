import React from 'react';
import styled from 'styled-components';

const CreatorHeader = ({
  btnLeftIcon,
  btnLeftLabel,
  onBack,
  btnRightIcon,
  btnRightLabel,
  onBtnRight,
  title,
}) => (
  <Wrapper>
    <Button onClick={onBack} left={true}>
      {btnLeftIcon || btnLeftLabel}
    </Button>

    <Title>{title}</Title>

    {onBtnRight ? (
      <Button onClick={onBtnRight}>{btnRightIcon || btnRightLabel}</Button>
    ) : (
      <Spacer />
    )}
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  border-bottom: 1px solid ${props => props.theme.colors.border};
  width: 100%;
`;

const Button = styled.button`
  outline: none;
  border: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 5px 0 0;
  width: 48px;
  height: 48px;
  background-color: transparent;

  svg {
    font-size: 32px;
    color: ${props => props.theme.textColors.primary};
  }

  ${({ left }) =>
    left &&
    `
		padding: 0 0 0 5px;
	`}
`;

const Spacer = styled.div`
  display: block;
  width: 48px;
  height: 48px;
`;

const Title = styled.h3`
  font-size: 15px;
  font-weight: bold;
  color: ${props => props.theme.textColors.primary};
  margin: 0;
  line-height: 1;
  flex: 1;
  text-align: center;
`;

export default CreatorHeader;
