import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { MdPause, MdPlayArrow } from 'react-icons/md';

const DURATION = 500;

const PlaybackIndicator = ({ paused }) => {
  const isInitialMount = useRef(true);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    setIsAnimating(true);

    const timeout = setTimeout(() => {
      setIsAnimating(false);
    }, DURATION);

    return () => clearTimeout(timeout);
  }, [paused]);

  return (
    <Wrapper>
      <Indicator animating={isAnimating}>
        {paused ? <MdPause /> : <MdPlayArrow />}
      </Indicator>
    </Wrapper>
  );
};

const fadeOut = keyframes`
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(2);
  }
`;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`;

const Indicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  opacity: 0;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
  animation-timing-function: ease-out;
  animation-duration: ${DURATION}ms;
  animation-name: ${props => (props.animating ? fadeOut : 'none')};
  animation-fill-mode: forwards;

  svg {
    color: white;
    font-size: 36px;
  }
`;

export default PlaybackIndicator;
