// @flow
import React, { useState, useContext, useEffect, useRef, useMemo } from 'react';
import styled from 'styled-components';
import { fetchFile } from '@ffmpeg/ffmpeg';
import { getTrackBackground, Range } from 'react-range';
import { MdCheck, MdExpandMore } from 'react-icons/md';
import { toTimeString, formatTimestamp } from '../../hooks/useTextHelper';
import { readFileAsBase64 } from '../../hooks/useVideoHelper';
import Modal from './Modal';
import CreatorHeader from '../creator/CreatorHeader';
import CreatorVideo from '../creator/CreatorVideo';
import CreatorTrimmerKnobTooltip from '../creator/CreatorTrimmerKnobTooltip';
import ProBanner from '../creator/ProBanner';
import { CreatorContext } from '../../contexts/CreatorContext';
import ActivityIndicator from '../common/ActivityIndicator';

const Knob = ({ isDragged, onDragStart, onDragEnd, libProps }) => {
  useEffect(() => {
    // only runs when dragging state changes, reports back to parent when drag starts/ends
    try {
      if (isDragged) onDragStart();
      else onDragEnd();
    } catch (err) {}
  }, [isDragged, onDragStart, onDragEnd]);

  return (
    <div
      {...libProps}
      style={{
        ...libProps?.style,
        height: '42px',
        width: '36px',
        borderRadius: '4px',
        backgroundColor: '#3AA9E6',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0 2px 6px rgba(232, 236, 250, 0.6)',
      }}>
      <div
        style={{
          height: isDragged ? '12px' : '16px',
          width: isDragged ? '4px' : '5px',
          borderRadius: '4px',
          backgroundColor: 'white',
        }}
      />
    </div>
  );
};

const ProThumbModal = ({
  isVisible,
  onModalClose,
  onThumbSelect,
  startTime,
  video,
  bounds,
  ffmpeg,
}) => {
  const {
    state: { file, filename, url },
  } = useContext(CreatorContext);

  const videoRef = useRef(null);

  const [currentTime, setCurrentTime] = useState([startTime]);
  const [showTooltip, setShowTooltip] = useState(false);
  const [loading, setLoading] = useState(false);

  const TOOLTIP_LEFT_POS = useMemo(() => {
    const relativeStart = currentTime[0] - bounds[0];
    const offset = bounds[1] - bounds[0];
    return (relativeStart / offset) * 100;
  }, [currentTime, bounds]);

  const onVideoError = (err) => {
    console.log('Error loading video: ', err);
  };

  const onSliderChange = (values) => {
    videoRef.current.currentTime = values[0].toString();
    setCurrentTime(values);
  };

  const onSave = async () => {
    setLoading(true);

    try {
      if (!ffmpeg.isLoaded()) await ffmpeg.load();
      ffmpeg.FS('writeFile', filename, await fetchFile(file));

      const thumbTimeInSecs = toTimeString(currentTime[0]);
      await ffmpeg.run(
        '-ss',
        thumbTimeInSecs,
        '-i',
        filename,
        '-t',
        '00:00:1.000',
        '-vf',
        `scale=150:-1`,
        `customThumb.png`,
      );

      const data = ffmpeg.FS('readFile', `customThumb.png`);

      const blob = new Blob([data.buffer], { type: 'image/png' });
      const dataURI = await readFileAsBase64(blob);
      ffmpeg.FS('unlink', `customThumb.png`);

      const thumbObj = {
        time: currentTime[0],
        image: dataURI,
      };

      onThumbSelect(thumbObj, true);
    } catch (err) {
      console.log('Error creating custom thumb', err);
      onThumbSelect(null);
    }

    setLoading(false);
  };

  return (
    <Modal
      isVisible={isVisible}
      animationIn="slideInUp"
      animationOut="slideOutDown"
      backdropColor="black"
      backdropOpacity={0.7}
      maxHeight={true}
      maxWidth={true}>
      <Container>
        <ProBanner />
        <CreatorHeader
          title="Select Thumbnail"
          btnLeftIcon={<MdExpandMore />}
          onBack={onModalClose}
        />
        <VideoWrapper>
          <CreatorVideo
            ref={videoRef}
            src={url}
            startTime={startTime}
            playbackBounds={bounds}
            onError={onVideoError}
            disabled
          />
        </VideoWrapper>
        <Content>
          <SliderWrapper>
            <CreatorTrimmerKnobTooltip
              show={showTooltip}
              center
              position={TOOLTIP_LEFT_POS}>
              {formatTimestamp(currentTime[0], true)}
            </CreatorTrimmerKnobTooltip>
            <Range
              values={currentTime}
              step={0.1}
              min={bounds[0]}
              max={bounds[1]}
              onChange={onSliderChange}
              onFinalChange={onSliderChange}
              renderTrack={({ props, children }) => (
                <div
                  onMouseDown={props.onMouseDown}
                  onTouchStart={props.onTouchStart}
                  style={{
                    ...props.style,
                    display: 'flex',
                    width: '100%',
                    height: '36px',
                  }}>
                  <div
                    ref={props.ref}
                    style={{
                      height: '6px',
                      width: '100%',
                      borderRadius: '4px',
                      background: getTrackBackground({
                        values: currentTime,
                        colors: ['#3AA9E6', '#F5F7FF'],
                        min: bounds[0],
                        max: bounds[1],
                      }),
                      alignSelf: 'center',
                    }}>
                    {children}
                  </div>
                </div>
              )}
              renderThumb={({ props, isDragged }) => (
                <Knob
                  key={props.key}
                  isDragged={isDragged}
                  onDragStart={() => setShowTooltip(true)}
                  onDragEnd={() => setShowTooltip(false)}
                  libProps={props}
                />
              )}
            />
          </SliderWrapper>
          <Footer>
            <SaveBtn disabled={loading} onClick={onSave}>
              {loading ? (
                <ActivityIndicator size={30} color="white" />
              ) : (
                <MdCheck />
              )}
            </SaveBtn>
          </Footer>
        </Content>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 0;
  overflow: hidden;
  background-color: white;
  flex: 1;
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 25px 20px;
  align-items: center;
  width: 100%;
`;

const VideoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 212px;
  width: 100%;
  background-color: ${(props) => props.theme.backgroundColors.primary};

  @media only screen and (min-width: 768px) {
    height: 360px;
  }
`;

const SliderWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 24px 12px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  pointer-events: all;
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: auto;
`;

const SaveBtn = styled.div`
  margin-top: 20px;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    color: white;
    font-size: 36px;
  }

  ${({ disabled }) =>
    disabled &&
    `
		opacity: 0.7;
		pointer-events: none;
	`}
`;

export default ProThumbModal;
