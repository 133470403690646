import React from 'react';
import styled from 'styled-components';
import { lighten } from 'polished';

const Button = ({
  outlined,
  primary,
  secondary,
  faded,
  large,
  onClick,
  children,
}) => (
  <Btn
    outlined={outlined}
    primary={primary}
    secondary={secondary}
    faded={faded}
    large={large}
    onClick={onClick}>
    {children}
  </Btn>
);

const Btn = styled.button`
  display: block;
  position: relative;
  border-radius: 4px;
  border: none;
  padding: ${(props) => (props.large ? '0 24px' : '0 20px')};
  height: ${(props) => (props.large ? '48px' : '32px')};
  line-height: 1;
  font-size: ${(props) => (props.large ? '16px' : '14px')};
  font-weight: 700;
  outline: none;
  cursor: pointer;

  ${({ outlined, primary, secondary, theme }) =>
    outlined &&
    `
			background: transparent !important;
			border: 1px solid ${
        primary
          ? theme.colors.primary
          : secondary
          ? theme.colors.secondary
          : theme.colors.navy
      };
			color: ${
        primary
          ? theme.colors.primary
          : secondary
          ? theme.colors.secondary
          : theme.colors.navy
      };

      &:hover {
        border-color: ${lighten(
          0.15,
          primary
            ? theme.colors.primary
            : secondary
            ? theme.colors.secondary
            : theme.colors.navy,
        )};
        color: ${lighten(
          0.15,
          primary
            ? theme.colors.primary
            : secondary
            ? theme.colors.secondary
            : theme.colors.navy,
        )};
      }
	`}

  ${({ faded }) =>
    faded &&
    `
		opacity: 0.5;

		&:hover {
			opacity: 1;
		}
	`}

	${({ secondary, primary, theme }) =>
    (primary || secondary) &&
    `
		background-color: ${primary ? theme.colors.primary : theme.colors.secondary};
		color: white;
	`}
`;

export default Button;
