export const DOCUMENT_TITLE = 'Clipbox';
export const VERBOSE = false;

export const CREATOR_MAX_DURATION = {
  free: 30,
  pro: 60,
};

export const CREATOR_MIN_DURATION = 1;

export const FREE_CLIP_COUNT = 10;

export const APP_ID = 'clipbox-nzfng';

export const AMPLITUDE = {
  prod: 'dd5b9a8021ba6941719addc459b90dfa',
  dev: '7dd31391346f9b6b16c9df6179516a58',
};

export const REMOVE_SOCIAL = true;

export const WEBAPP_URL = {
  dev: 'http://localhost:7200',
  // dev: 'https://web.clipbox.app',
  beta: 'http://localhost:7200',
  prod: 'https://web.clipbox.app',
};
