// @flow
import React from 'react';
import AlertContextProvider from './contexts/AlertContext';
import CreatorContextProvider from './contexts/CreatorContext';
import ModalContextProvider from './contexts/ModalContext';
import SystemContextProvider from './contexts/SystemContext';

const Root = ({ children }) => (
  <SystemContextProvider>
    <CreatorContextProvider>
      <ModalContextProvider>
        <AlertContextProvider>{children}</AlertContextProvider>
      </ModalContextProvider>
    </CreatorContextProvider>
  </SystemContextProvider>
);

export default Root;
